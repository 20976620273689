import '@lendica/ibranchtrigger';
import { useEffect, useRef, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { api } from './api.service';
import Button from './components/button';
import HelpPopup from './components/help-popup';
import FormModal from './forms/form-modal';
import { useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { IconMoneybag } from '@tabler/icons-react';
import ContentCard from './components/content-card';

export default function Home() {
  const [data, setData] = useState();
  const [totalRevenue, setTotalRevenue] = useState();
  const [activeRevenue, setActiveRevenue] = useState();
  const [percentageOwed, setPercentageOwed] = useState(0);
  const [suggestedCredit, setSuggestedCredit] = useState();
  const [total, setTotal] = useState({});
  const [initialised, setInitialised] = useState(false);
  const navigate = useNavigate();
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');

  useEffect(() => {
    if (!localStorage.getItem('settingsOnboarding') && buildrfiFinancingEnabled && !(data?.creditApp)) {
      localStorage.setItem('settingsOnboarding', 't');
      navigate('/financing');
    }
  }, [buildrfiFinancingEnabled]);

  const topActiveProjects = data?.projects
    ?.filter((p) => p.status === 'Active')
    ?.sort((a, b) => b.totalPrice - a.totalPrice)
    ?.slice(0, buildrfiFinancingEnabled ? 2 : 1);

  const getTotalBillsAndReceivables = (projectId) => {
    let totalBills = 0;
    let totalReceivables = 0;

    if (data) {
      for (let i of data.invoices.filter((x) => x.project === projectId)) {
        totalReceivables += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      }
      for (let b of data.bills.filter((x) => x.project === projectId)) {
        totalBills += +(b.tax ?? 0) + +(b.subtotal ?? 0);
      }
    }

    return { totalBills, totalReceivables };
  };

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
      setData(x);
    });
  }, []);

  useEffect(() => {
    let activetotal = 0;
    let total = 0;
    let owed = 0;
    let paid = 0;
    let totalSpend = 0;
    let totalEstimatedSpend = 0;
    if (data?.projects?.length)
      for (let p of data.projects) {
        let totals = getItemTotals(p._id, p.deposit);
        if (p.totalPrice && p.status == 'Active') activetotal += +p.totalPrice;
        if (p.totalPrice) total += +p.totalPrice;
        if (totals.totalPaidToDate) paid += totals.totalPaidToDate;
        if (totals.totalOutstanding) owed += totals.totalOutstanding;
        if (totals.totalCosts) totalSpend += totals.totalCosts;
        if (p.totalEstimatedCosts)
          totalEstimatedSpend += +p.totalEstimatedCosts;
      }
    setPercentageOwed(Math.round((owed / total) * 100 || 0));
    setTotalRevenue(total);
    setActiveRevenue(activetotal);
    setSuggestedCredit(Math.round(owed * 1.75));
    let percentageComplete = Math.min((totalSpend / totalEstimatedSpend) * 100, 100);
    if (isNaN(percentageComplete)) percentageComplete = 0;
    percentageComplete = Math.round(percentageComplete);
    setTotal({
      total,
      owed,
      paid,
      totalSpend,
      totalEstimatedSpend,
      percentageComplete,
    });
  }, [data]);

  const linksRef = useRef();
  const linksVisible = useIntersectionObserver(linksRef, {
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    if (linksVisible) {
      linksRef.current.classList.remove('invisible');
      linksRef.current.classList.add('animate-fade-up');
    }
  }, [linksVisible]);

  const contentRef = useRef();
  const contentVisible = useIntersectionObserver(contentRef, {
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    if (contentVisible) {
      contentRef.current.classList.remove('invisible');
      contentRef.current.classList.add('animate-fade-up');
    }
  }, [contentVisible]);

  useEffect(() => {
    if (window.lendica && data && !initialised && !buildrfiFinancingEnabled) {
      const credentials = {
        partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
        partner_company_uuid: data.companyData.id,
        company_name: data.companyData.companyName,
      };

      window.lendica.init(credentials).then(() => {
        window.lendica.ibranch.render();
        setInitialised(true);
      });
    }
  }, [window.lendica, data]);

  const getItemTotals = (projectId, depositPaid) => {
    let totalOutstanding = 0;
    let totalPaidToDate = +(depositPaid ?? 0);
    let totalCosts = 0;
    for (let i of data.invoices.filter((x) => x.project == projectId)) {
      if (i.paid) {
        totalPaidToDate += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      } else {
        totalOutstanding += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      }
    }
    for (let b of data.bills.filter((x) => x.project == projectId)) {
      totalCosts += +(b.tax ?? 0) + +(b.subtotal ?? 0);
    }
    return { totalOutstanding, totalPaidToDate, totalCosts };
  };

  return (
    <div className="flex flex-col max-w-screen">
      <div>
        <div className="w-full  md:max-w-5xl p-5 mx-auto">
          <div className="grid grid-cols-1 gap-10 lg:grid-cols-4">
            <div className="max-w-screen flex flex-col flex-wrap md:flex-nowrap md:grid md:grid-cols-1 gap-5 col-span-4 lg:grid-cols-2">
              {buildrfiFinancingEnabled && data?.creditApp?.status?.toLowerCase() !== 'verified' &&
                data?.creditApp?.status?.toLowerCase() !== 'rejected' &&
                data?.creditApp?.status?.toLowerCase() !== 'pending'
                && (
                  <div className="md:h-full col-span-2 row-span-1 bg-primary rounded-lg shadow animate-fade-right border bg-gradient-to-tr from-yellow-500 to-orange-600 p-1">
                    <div className='bg-primary w-full h-full top-0 left-0 rounded-sm shadow-md flex flex-col items-center py-4'>
                      <p className="mb-6 font-bold tracking-tight text-center text-gray-700 sm:text-xl flex flex-row">
                        Welcome to BuildrFi Financing!
                        <IconMoneybag className="w-6 h-6 ml-2 text-gray-700" />
                      </p>
                      <p className='w-2/3 text-center'>You qualify for financing with BuildrFi! Get started by filling out your application now.</p>
                      <Button
                        onClick={() => navigate('/financing')}
                        text="Apply Now"
                        className="border-2 border-yellow-800 p-3 px-6 rounded-full !shadow-md animate-fade w-1/2 max-w-sm hover:scale-105 mt-6 mb-2 !font-semibold !text-lg"
                      />
                    </div>
                  </div>
                )}

              <div className="md:h-full p-6 bg-primary rounded-lg shadow flex flex-col items-center animate-fade-right border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-gray-700 sm:text-xl">
                  Active Projects
                </div>
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-gray-800 sm:text-3xl">
                  {data?.projects?.length
                    ? data?.projects?.filter((x) => x.status == 'Active').length
                    : '0'}
                </div>
                <Button
                  onClick={() => {
                    navigate('/project/new');
                  }}
                  text={
                    data?.projects?.length
                      ? data.projects.filter((x) => x.status === 'Active')
                        .length
                        ? 'Add a New Project'
                        : 'Create your first Project'
                      : 'Create your first Project'
                  }
                  className="w-full md:w-3/4 border-2 hover:scale-105 border-yellow-800 p-3 px-6 !rounded-full !shadow-md animate-fade "
                />
              </div>

              <div className="p-6 flex flex-col bg-primary rounded-lg shadow animate-fade-right animate-delay-100 border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-gray-700 sm:text-xl">
                  Active Projects Contract Value
                </div>
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-gray-800 sm:text-3xl">
                  {activeRevenue ? `$${activeRevenue.toLocaleString()}` : '0'}
                </div>
              </div>

              <div className="relative flex flex-col items-center md:h-full p-6 mb-5 bg-primary rounded-lg shadow animate-fade-right animate-delay-200 border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-gray-700 sm:text-xl">
                  Percentage Complete
                </div>
                <div className="absolute right-10 top-8">
                  <HelpPopup text="% costs to date out of total estimated costs for all projects"></HelpPopup>
                </div>
                <div>
                  <CircularProgressbar
                    styles={buildStyles({
                      pathTransitionDuration: 2,
                      pathColor: `rgb(245, 205, 70)`,
                      textColor: 'var(--color-gray-800)',
                      trailColor: '#d6d6d6',
                    })}
                    className="w-24 h-24"
                    value={percentageOwed}
                    text={`${Math.min(percentageOwed, 100)}%`}
                  />
                </div>
              </div>

              <div className="relative flex flex-col items-center md:h-full p-6 mb-5 bg-primary rounded-lg shadow animate-fade-right animate-delay-200 border">
                <div className="mb-8 text-xl font-bold tracking-tight text-center text-gray-700 sm:text-xl">
                  Paid to Date - ${total.paid}
                </div>
                <div>
                  <CircularProgressbar
                    styles={buildStyles({
                      pathTransitionDuration: 2,
                      pathColor: `rgb(245, 205, 70)`,
                      textColor: 'var(--color-gray-800)',
                      trailColor: '#d6d6d6',
                    })}
                    className="w-24 h-24"
                    value={(total.paid / total.total) * 100 || 0}
                    text={`${Math.round((total.paid / total.total) * 100 || 0)}%`}
                  />
                </div>
              </div>

              <div className={`md:h-full p-6 text-center bg-yellow-200 rounded-lg shadow border col-span-2 md:min-h-[200px]`}>
                <div className="text-xl font-bold tracking-tight text-center text-gray-800 sm:text-xl mb-2">
                  Quick Project Access
                </div>
                <div className="flex flex-row gap-x-4 space-between w-full">
                  {topActiveProjects?.length ? (
                    topActiveProjects.map((project) => {
                      const { totalBills, totalReceivables } =
                        getTotalBillsAndReceivables(project._id);
                      const startDate = project.startDate
                        ? new Date(project.startDate).toLocaleDateString()
                        : 'N/A';
                      const endDate = project.completedDate
                        ? new Date(project.completedDate).toLocaleDateString()
                        : 'N/A';

                      return (
                        <div
                          key={project._id}
                          onClick={() => navigate(`/project/${project._id}`)}
                          className="grid grid-cols-2 gap-2 p-3 bg-primary transition-all hover:scale-105 hover:bg-primary shadow-md rounded-lg hover:shadow-lg cursor-pointer text-sm border w-full"
                        >
                          <div className="col-span-2 text-base font-semibold text-gray-800">
                            {project.name}
                            <span className="font-normal text-gray-400 text-xs">
                              ({startDate} - {endDate})
                            </span>
                          </div>
                          <div className="text-gray-500">Value:</div>
                          <div className="text-gray-800 font-semibold">
                            ${project.totalPrice?.toLocaleString() || 'N/A'}
                          </div>
                          <div className="text-gray-500">Total Bills:</div>
                          <div className="text-gray-800 font-semibold">
                            ${totalBills.toLocaleString() || 'N/A'}
                          </div>
                          <div className="text-gray-500">
                            Total Receivables:
                          </div>
                          <div className="text-gray-800 font-semibold">
                            ${totalReceivables.toLocaleString() || 'N/A'}
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No active projects available.</div>
                  )}
                </div>
              </div>
            </div>

            {/* Resources */}
            <div className='col-span-4' >
              <div className="text-xl font-medium text-gray-800 mb-3">
                Resources for you
              </div>
              <div
                ref={contentRef}
                className="w-full flex flex-col gap-4"
              >
                {data?.content?.map((contentItem) => (
                  <ContentCard
                    key={contentItem._id}
                    contentItem={contentItem}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}