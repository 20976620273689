// Imports
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';

import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import Table from '../components/table';
import { getStatusClass } from '../utils';

const EXPORT_TABLE_COLUMNS = [
    'Business',
    'Invoice ID',
    'Amount',
    'Status',
    'Created At',
];

const FINANCING_REQUESTS_TABLE_COLUMNS = [
    {
        Header: 'Business',
        accessor: 'businessName',
    },
    {
        Header: 'Invoice #',
        accessor: 'invoiceNumber',
    },
    {
        Header: 'Vendor Name',
        accessor: 'vendorName',
    },
    {
        Header: 'Amount',
        accessor: 'amount',
        Cell: ({ value }) => (value ? `$${value.toFixed(2)}` : 'N/A'),
    },
    {
        Header: 'Status',
        accessor: 'status',
        Cell: ({ value }) => (
            <span className={`${getStatusClass(value)} capitalize font-bold`}>
                {value}
            </span>
        ),
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'N/A'),
    },
    {
        Header: 'Due Date',
        accessor: 'dueDate',
        Cell: ({ value }) => (value ? new Date(value).toLocaleDateString() : 'N/A'),
    },
    {
        Header: 'Actions',
        accessor: 'actions',
    },
];

const CellViewDetails = ({ viewDetailsCallback }) => (
    <span
        className="underline cursor-pointer text-blue-500"
        onClick={viewDetailsCallback}
    >
        View Details
    </span>
);

const AdminFinancingRequestsList = () => {
    const [financingRequests, setFinancingRequests] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await api(
                `${process.env.REACT_APP_API_URL}/admin/financing-requests`
            );
            setFinancingRequests(result);
        } catch (err) {
            setError('Error loading data');
            toast.error('Failed to load financing requests');
        } finally {
            setLoading(false);
        }
    };

    if (loading || !financingRequests || financingRequests.length === 0) return <Loading />;
    if (error) return <div>{error}</div>;

    // Sort financing requests by status if needed
    const statusOrder = {
        Pending: 1,
        Approved: 2,
        Rejected: 3,
    };

    const sortedFinancingRequests = [...financingRequests].sort((a, b) => {
        const statusA = statusOrder[a.status] || 4;
        const statusB = statusOrder[b.status] || 4;
        return statusA - statusB;
    });

    const unreviewed = financingRequests.filter((req) => req.documentReviewed !== true);

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} />
            <PageHeader headline={'Financing Requests Management'} />

            {unreviewed.length > 0 && (
                <div className="mx-auto my-4 w-full max-w-6xl bg-yellow-50 border border-yellow-300 rounded p-4">
                    <h2 className="text-2xl font-bold mb-2">Unreviewed Documents</h2>
                    <Table
                        columns={FINANCING_REQUESTS_TABLE_COLUMNS}
                        exportColumns={EXPORT_TABLE_COLUMNS}
                        data={unreviewed.map((request) => ({
                            businessName: request.business?.name || 'N/A',
                            invoiceNumber: request.invoice?.invoiceNumber || 'N/A',
                            vendorName: request.invoice?.vendorName || 'N/A',
                            dueDate: request?.invoice?.dueDate || 'N/A',
                            amount: request.amount,
                            status: request.status || 'Unknown',
                            createdAt: request.createdAt,
                            actions: (
                                <CellViewDetails
                                    key={request._id}
                                    viewDetailsCallback={() =>
                                        navigate(`/admin/financing-request/detail/${request._id}`)
                                    }
                                />
                            ),
                        }))}
                        exportData={unreviewed.map((request) => ({
                            'Business': request.businessId?.name || 'N/A',
                            'Invoice ID': request.invoice?.invoiceNumber || 'N/A',
                            'Amount': request.amount
                                ? `$${request.amount.toFixed(2)}`
                                : 'N/A',
                            'Status': request.status || 'Unknown',
                            'Created At': request.createdAt
                                ? new Date(request.createdAt).toLocaleDateString()
                                : 'N/A',
                        }))}
                    />
                </div>
            )}

            <div className="flex">
                <div className="w-full max-w-6xl pb-20 mx-auto my-10 2xl:max-w-6xl">
                    <Table
                        columns={FINANCING_REQUESTS_TABLE_COLUMNS}
                        exportColumns={EXPORT_TABLE_COLUMNS}
                        data={sortedFinancingRequests.map((request) => (
                            {
                                businessName: request.business?.name || 'N/A',
                                invoiceNumber: request.invoice?.invoiceNumber || 'N/A',
                                vendorName: request.invoice?.vendorName || 'N/A',
                                amount: request.amount,
                                status: request.status || 'Unknown',
                                createdAt: request.createdAt,
                                dueDate: request?.invoice?.dueDate || 'N/A',
                                actions: (
                                    <CellViewDetails
                                        key={request._id}
                                        viewDetailsCallback={() =>
                                            navigate(
                                                `/admin/financing-request/detail/${request._id}`
                                            )
                                        }
                                    />
                                ),
                            }))}
                        exportData={sortedFinancingRequests.map((request) => ({
                            'Business': request.businessId?.name || 'N/A',
                            'Invoice ID': request.invoice?.invoiceNumber || 'N/A',
                            'Amount': request.amount
                                ? `${request.amount.toFixed(2)}`
                                : 'N/A',
                            'Status': request.status || 'Unknown',
                            'Created At': request.createdAt
                                ? new Date(request.createdAt).toLocaleDateString()
                                : 'N/A',
                        }))}
                    />
                </div>
            </div>
        </>
    );
};

export default AdminFinancingRequestsList;