export const PROJECT_LLM_CONFIG = {
  config: {
    name: "the project name mentioned in the contract",
    location: "the location of the project from the contract",
    description: "a brief summary of the project. what they are building, how theyre doing it, what materials",
    projectOwner: "the name of the project owner from the contract",
    mainContact: "the main contact person for the project as listed in the contract",
    mainContactEmail: "the email of the main contact person from the contract",
    startDate: "the project start date mentioned in the contract (in a recognizable date format)",
    completedDate: "the target or expected completion date of the project from the contract (in a recognizable date format)",
    status: "the current project status (ONLY THESE STATUSES: In Review, Active, or Complete). Use the start date to determine if the project is active or completed.",
    totalPrice: "the total contract value or total price of the project mentioned in the contract",
    deposit: "the deposit amount paid upfront as listed in the contract",
    totalEstimatedCosts: "the total estimated costs for completing the project from the contract",
    documentContext: "Project contract document, between general contractor (or owner) and the subcontractor. The party doing the work/labor is the subcontractor. The party contracting the work is the general contractor.",
  },
  extraDocumentData: {
    baseContractAmount: "Base contract amount (decimal). Format: numeric. Example: 1000000. Importance: High.",
    projectStartDate: "Project start date (ISO 8601, YYYY-MM-DD). Example: 2024-06-03. Importance: High.",
    projectEndDate: "Project end date (if available) (ISO 8601, YYYY-MM-DD). Example: 2024-06-03. Importance: Medium.",
    jobAddress: "Address where the job is to be performed (text). Example: 1234 Main St, Anytown, USA.",
    mainTradeOrScope: "Main trade or scope of work (text). Example: Roofing. Importance: High.",
    contractWorkBreakdown: "Contract work breakdown (array of objects with keys: item, value, unit, qty). Format: JSON array. Example: [{ item: 'Concrete', value: 100000, unit: 'LS', qty: 1 }]. Importance: High.",
    basicPaymentTerms: "Basic payment terms (text). Example: Net 30, When Paid, Monthly Submittals. Importance: High.",
    paymentFrequency: "Payment frequency (text). Example: Monthly. Importance: High.",
    retentionPercentage: "Retention percentage (decimal). Format: numeric percentage. Example: 10. Importance: High.",
    releaseConditions: "Release conditions (text). Example: Substantial completion. Importance: Medium.",
    initialDeposit: "Initial deposit amount (decimal). Format: numeric. Example: 50000. Importance: High.",
    acceptableFormToSubmitPaymentRequest: "Acceptable form to submit payment request (enum). Format: string value. Example: 'AIA G702' or 'G701'. Importance: High.",
    acceptableFormToSubmitChangeOrders: "Acceptable form to submit change orders (enum). Format: string value. Example: 'AIA G701' or 'G701'. Importance: High.",
    stepByStepProcessForSubmittingPaymentRequests: "Step-by-step process for submitting payment requests (text). Example: A detailed list of steps. Importance: High.",
    documentedProcessForSubmittingAndApprovingChangeOrders: "Documented process for submitting and approving change orders (text). Example: A written procedure outlining the process. Importance: High.",
    gcDetails: "General Contractor details (object with name and contact). Format: JSON object. Example: { name: 'ABC GC', contact: 'john@abc.com' }. Importance: High.",
    ownerDetails: "Owner details (object with name and contact). Format: JSON object. Example: { name: 'XYZ Dev', contact: 'mary@xyz.com' }. Importance: High.",
    requiredCoverageCOI: "Required coverage for COI (object with type and amount). Format: JSON object. Example: { type: 'GL', amount: 1000000 }. Importance: High.",
    bondRequirements: "Indicates if a bond is required to perform the job (text). Example: Performance Bond Required. Importance: High.",
    bondDetails: "Details of the bond if a bond is required (object) Format: JSON object. Example: { 'type': 'Performance', 'amount': 500000, 'issuer': 'ABC Surety' } or null. Importance: High.",
    delayPenalty: "Monetary Penalty for project delays (object) Format: JSON object. Example: { 'per_day': 500, 'max_days': 30 } or null. Importance: High.",
    otherPenalties: "Other Penalties (object) Format: JSON object. Example: [ { 'type': 'Safety Violation', 'amount': 1000 }, { 'type': 'Storage Misuse', 'amount': 500 } ] or null. Importance: High.",
  }
};

export const INVOICE_LLM_CONFIG = {
  config: {
    invoiceNumber: "the invoice number from the document",
    orderDate: "the order date from the document",
    dueDate: "the due date from the document",
    subtotal: "the subtotal, must be a numeric value",
    tax: "the tax, must be a numeric value",
    documentContext: "Invoice document is a request for payment for contracted work that has been completed. The party doing the work/labor is the subcontractor. The party contracting the work is the general contractor.",
  },
  extraDocumentData: {
    original_contract: "Original contract amount (decimal). Format: numeric. Example: 1000000.",
    approved_changes: "Total approved COs (decimal). Format: numeric. Example: 50000.",
    revised_contract: "Contract + changes (decimal). Format: numeric. Example: 1050000.",
    sov_complete: "Work complete by line item (array of objects with keys: item, percent, value). Format: JSON array. Example: [{ item: 'Concrete', percent: 75, value: 75000 }].",
    total_complete: "Total work completed (decimal). Format: numeric. Example: 750000.",
    stored_materials: "Materials not installed (decimal). Format: numeric. Example: 50000.",
    previous_payments: "Prior amounts paid (decimal). Format: numeric. Example: 500000.",
    current_request: "This period request (decimal). Format: numeric. Example: 250000.",
    retainage_held: "Total retention held (decimal). Format: numeric. Example: 75000.",
    balance_remaining: "Work left to bill (decimal). Format: numeric. Example: 250000.",
    period_start: "Billing period start date (YYYY-MM-DD). Example: 2024-01-01.",
    period_end: "Billing period end date (YYYY-MM-DD). Example: 2024-01-31."
  }
};

export const BILL_LLM_CONFIG = {
  config: {
    invoiceNumber: "unique identifier (invoice/PO number). Typically found in the header with prefixes like 'INV-', 'PO-', or '#'",
    date: "the order date from the document",
    subtotal: "the subtotal amount listed, formatted as a number",
    tax: "Extract the tax amount. If multiple taxes, sum them. If none found use 0.00",
    dueDate: "due date from the bill. If a specific date is not provided, determine it based on terms such as 'X days after delivery' or 'net X days' using the bill date or delivery date as a reference",
    documentContext: "Bill document is receipt or order for material purchases from a material supplier. The party purchasing the material is the subcontractor.",
  },
  extraDocumentData: {
    standardTerms: "standard payment timeline (e.g., 'Net 30', 'Due on Receipt'). Look for 'Terms:', 'Payment Terms:'",
    earlyPaymentDiscount: "Early payment discount info in {percentage, days}, Example: '2%, 10 days'",
    lineItems: "Array of individual items. Example: '[{desc: 'Wood', qty: 10, unit: 'each', price: 100}]'",
    materialCategory: "Type of material. text. Example: 'Lumber'",
    specifications: "Material specs/grade. text. Example: 'Grade #2'",
    volumeDiscounts: "Quantity-based discount object {threshold, discount}. Example: '{1000, 5%}'",
    projectName: "Extract the project name or identifier. Look for 'Project:', 'Job:'",
    projectAddress: "Delivery location. text. Example: '123 Main St'",
    contractNumber: "Contract reference. text. Example: 'CTR-2024-001'",
    changeOrderNumber: "Change order reference. text. Example: 'CO-123'",
    workScope: "Scope description. text. Example: 'Phase 1'",
    billToName: "Subcontractor name. text. Example: 'XYZ Construction'",
    billToAddress: "Subcontractor address. text. Example: '456 Oak Ave'",
    materialDestination: "Delivery location if different. text. Example: 'Site B'",
    vendorName: "Supplier name. text. Example: 'BuildMart Supply'",
    vendorAddress: "Supplier address. text. Example: '100 Supply Dr'",
    vendorContact: "Contact details object {name, phone, email}. Example: '{ name: John, phone: 555-1234, email: john@example.com }'",
    creditLimit: "Available credit. decimal. Example: '50000'",
    lienWaiverType: "Type of lien waiver. text. Example: 'Conditional'",
    throughDate: "Coverage period end. Format: 'YYYY-MM-DD'. Example: '2024-02-29'"
  }
};