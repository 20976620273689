import React from 'react';
import Modal from '../components/modal';
import ProjectForm from './project-form';

export default function EmbeddedProjectCreationForm({ isOpen, onClose, onProjectCreated }) {
    if (!isOpen) return null;

    async function handleProjectCreated(values) {
        if (onProjectCreated) {
            onProjectCreated(values);
        }
        onClose();
    }

    return (
        <Modal onClose={onClose} darken={true} containerClassName="w-full h-full max-h-[75vh] max-w-[75vw] mx-auto p-6 flex flex-col overflow-scroll">
            <ProjectForm
                id="new"
                callback={() => handleProjectCreated()}
                simple={true}
            />
        </Modal>
    );
}
