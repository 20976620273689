import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// Api Service
import { api, api_delete, api_update } from '../api.service';
// Components
import Confirm from '../components/confirm';
import CheckboxField from './fields/checkbox-field';
import CurrencyField from './fields/currency-field';
import DateField from './fields/date-field';
import FileField from './fields/file-field';
import PhoneField from './fields/phone-field';
import SelectField, { ADD_VENDOR_OPTION_ID } from './fields/select-field';
import TextField from './fields/text-field';
import FormWrapper from './form-wrapper';
import PayLaterButton from '../components/pay-later-button';
import { IconMoneybag, IconBuildingSkyscraper, IconUser, IconMail, IconPhone, IconMapPin } from '@tabler/icons-react';
import { toast } from 'react-toastify';
import { LlmFileExtractor } from '../components/llm-file-extractor';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { BILL_LLM_CONFIG } from '../config/llmConfigs';

export default function BillForm({ ...props }) {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isVendorSelected, setIsVendorSelected] = useState(false);
  const [isNewVendor, setIsNewVendor] = useState(false);
  const [values, setValues] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const id = params.id;

  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');
  const [extraBodyData, setExtraBodyData] = useState({});

  const [billPreviewUrl, setBillPreviewUrl] = useState(null);

  useEffect(() => {
    if (values?.document?.key) {
      api(`${process.env.REACT_APP_API_URL}/document/download-url?key=${values.document.key}`)
        .then((res) => {
          if (res?.url) {
            setBillPreviewUrl(res.url);
          }
        })
        .catch((err) => {
          console.error('Error fetching Bill doc preview URL', err);
        });
    } else {
      setBillPreviewUrl(null);
    }
  }, [values]);


  const deleteProject = () => {
    api_delete(`${process.env.REACT_APP_API_URL}/app/bill/${id}`).then(() =>
      navigate(-1),
    );
  };

  const handleFieldChange = async (key, value, values, setValues) => {
    if (id === value || key !== 'vendorId') return;
    if (value === ADD_VENDOR_OPTION_ID) {
      setIsVendorSelected(true);
      setIsNewVendor(true);
      setValues({
        ...values,
        vendorId: '',
        vendorName: '',
        vendorContactFirstName: '',
        vendorContactLastName: '',
        vendorContactEmail: '',
        vendorContactPhoneNumber: '',
        vendorContactCompanyAddress: '',
      });
      return;
    }

    setIsVendorSelected(true);
    setIsNewVendor(false);

    setValues({
      ...values,
      vendorId: '',
      vendorName: '',
      vendorContactFirstName: '',
      vendorContactLastName: '',
      vendorContactEmail: '',
      vendorContactPhoneNumber: '',
      vendorContactCompanyAddress: '',
    });

    const selectedVendorInfo = await api(
      `${process.env.REACT_APP_API_URL}/app/vendor/${value}`,
    );

    setValues({
      ...values,
      vendorId: value,
      vendorName: selectedVendorInfo.name,
      vendorContactFirstName: selectedVendorInfo.firstName,
      vendorContactLastName: selectedVendorInfo.lastName,
      vendorContactEmail: selectedVendorInfo.email,
      vendorContactCompanyAddress: selectedVendorInfo.companyAddress,
      vendorContactPhoneNumber: selectedVendorInfo.phoneNumber,
    });
  };

  useEffect(() => {
    if (!props.noId && id && id !== 'new') {
      api(`${process.env.REACT_APP_API_URL}/app/bill/${id}`).then((x) => {
        setValues(x ?? {});
        if (x && x.vendorName) {
          setIsVendorSelected(true);
        }
      });
    }
  }, [id]);

  async function handleFormSubmit(values) {
    if (values?.document) {
      await api_update(
        `${process.env.REACT_APP_API_URL}/document/${values?.document}`,
        { source: 'bill', sourceId: values._id },
      );
    }
    const path = '/bill/' + values._id;
    navigate(path);
    toast.success('Bill created successfully');
  }

  const formatDate = (date) => {
    if (!date || !Date.parse(date)) {
      return null;
    }
    return new Date(date).toISOString().split("T")[0];
  };

  return (
    <div className="px-8 relative">
      <Confirm
        open={confirmDelete}
        confirm={() => deleteProject()}
        cancel={() => setConfirmDelete(false)}
      ></Confirm>
      <FormWrapper
        url="app/bill"
        id={id}
        {...props}
        callback={props.callback || (async (values) => handleFormSubmit(values))}
        validate={() => true}
        extraBodyData={extraBodyData}
      >
        {(values, setValues) => {
          const getHiddenStyle = () => {
            if (id && id !== 'new') {
              return {};
            }
            return (!values.document)
              ? { opacity: 0, height: 0, overflow: 'hidden', pointerEvents: 'none' }
              : {};
          };
          return (
            <>
              <div className="">
                <div className="text-3xl font-bold tracking-tighter text-gray-800 mb-4">
                  Bill Details
                </div>
                <LlmFileExtractor
                  config={BILL_LLM_CONFIG.config}
                  multiple={false}
                  hideTitle={false}
                  extraDocumentData={BILL_LLM_CONFIG.extraDocumentData}
                  onExtracted={async (data) => {
                    setValues((prevValues) => ({
                      ...prevValues,
                      invoiceNumber: data.invoiceNumber ?? prevValues.invoiceNumber,
                      orderDate: data.date ? formatDate(data.date) : prevValues.orderDate,
                      subtotal: data.subtotal ?? prevValues.subtotal,
                      tax: data.tax ?? prevValues.tax,
                      dueDate: data.dueDate ? formatDate(data.dueDate) : prevValues.dueDate,
                    }));
                    setExtraBodyData({ extraDocumentData: data.extraDocumentData });
                  }}
                  filename={values.invoiceNumber + ' bill'}
                  value={values.document}
                  onChange={(val) => setValues({ ...values, document: val })}
                  className="w-full"
                  source="bill"
                  required
                />
              </div>
              <div style={getHiddenStyle()}>
                {values?._id && (
                  <div className="!absolute -top-2 -right-2 flex flex-row items-center justify-center bg-secondary-200 p-2 rounded-md">
                    {buildrfiFinancingEnabled && (
                      <>
                        <p className="mr-4">Financing:</p>
                        <PayLaterButton
                          bill={values}
                          preload={true}
                          className="animate-tada"
                        />
                      </>
                    )}
                    <CheckboxField
                      label="Mark as paid"
                      value={values.paid}
                      onChange={(val) => setValues({ ...values, paid: val })}
                      className="ml-4 !mt-0"
                      inline={true}
                    />
                  </div>
                )}

                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                  <SelectField
                    optionListName="projects"
                    label="Project"
                    value={values.project?._id || values.project}
                    onChange={(val) => setValues({ ...values, project: val })}
                  />
                  <TextField
                    label="Order Number"
                    value={values.invoiceNumber}
                    onChange={(val) => setValues({ ...values, invoiceNumber: val })}
                    required
                  />
                </div>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-4">
                  <CurrencyField
                    label="Subtotal"
                    value={values.subtotal}
                    onChange={(val) => setValues({ ...values, subtotal: val })}
                    min={0}
                  />
                  <div className="flex items-start justify-start relative w-full gap-1">
                    {!values.taxExempt && (
                      <div className="relative">
                        <input
                          value={Number(values.tax)}
                          step={0.01}
                          className="opacity-0 absolute bottom-0 right-0 w-full"
                          type="number"
                          min={1}
                          required={true}
                        />
                        <CurrencyField
                          label="Tax"
                          value={values.tax}
                          onChange={(val) => setValues({ ...values, tax: val })}
                          min={0.01}
                          className="w-full"
                          required={true}
                        />
                      </div>
                    )}
                    {values.taxExempt && <div className="w-full"></div>}
                    <CheckboxField
                      label="Tax Exempt?"
                      value={values.taxExempt}
                      onChange={(val) =>
                        setValues({
                          ...values,
                          taxExempt: val,
                          tax: val ? 0 : values.tax,
                        })
                      }
                      labelClass="text-xs"
                      className="w-1/2"
                      inputClass="mx-auto"
                    ></CheckboxField>
                  </div>
                  <DateField
                    label="Order Date"
                    value={values.orderDate}
                    onChange={(val) => setValues({ ...values, orderDate: val })}
                  />
                  <DateField
                    label="Due Date"
                    value={values.dueDate}
                    onChange={(val) => setValues({ ...values, dueDate: val })}
                  />
                </div>
                <div className="mt-10 mb-2 text-3xl font-bold tracking-tighter text-gray-800">
                  Vendor Details
                </div>
                <div className="grid grid-cols-1 gap-4 mb-7 sm:grid-cols-2">
                  <SelectField
                    label="Find your vendor"
                    value={values.vendorId || values.vendor?._id || values.vendor}
                    optionListName="vendors"
                    required={!isVendorSelected}
                    onChange={(val) =>
                      handleFieldChange("vendorId", val, values, setValues)
                    }
                  />
                </div>
                {isVendorSelected && (
                  <>
                    {isNewVendor ? (
                      <>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                          <TextField
                            label="Vendor Name"
                            value={values.vendorName}
                            onChange={(val) =>
                              setValues({ ...values, vendorName: val })
                            }
                            required
                          />
                          <TextField
                            label="Vendor Contact First Name"
                            value={values.vendorContactFirstName}
                            onChange={(val) =>
                              setValues({ ...values, vendorContactFirstName: val })
                            }
                            required
                          />
                          <TextField
                            label="Vendor Contact Last Name"
                            value={values.vendorContactLastName}
                            onChange={(val) =>
                              setValues({ ...values, vendorContactLastName: val })
                            }
                            required
                          />
                        </div>
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                          <TextField
                            label="Vendor Contact Email"
                            value={values.vendorContactEmail}
                            onChange={(val) =>
                              setValues({ ...values, vendorContactEmail: val })
                            }
                            required
                          />
                          <PhoneField
                            label="Vendor Contact Phone Number"
                            value={values.vendorContactPhoneNumber}
                            onChange={(val) =>
                              setValues({ ...values, vendorContactPhoneNumber: val })
                            }
                            required
                          />
                          <TextField
                            label="Vendor Contact Company Address"
                            value={values.vendorContactCompanyAddress}
                            onChange={(val) =>
                              setValues({ ...values, vendorContactCompanyAddress: val })
                            }
                            required
                          />
                        </div>
                      </>
                    ) : (
                      <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 p-8 border bg-gray-100 rounded-md">
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600 flex items-center">
                            <IconBuildingSkyscraper size={16} className="mr-1" />
                            Vendor Name
                          </span>
                          <p className="font-medium text-gray-800">{values.vendorName}</p>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600 flex items-center">
                            <IconUser size={16} className="mr-1" />
                            Contact First Name
                          </span>
                          <p className="font-medium text-gray-800">{values.vendorContactFirstName}</p>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600 flex items-center">
                            <IconUser size={16} className="mr-1" />
                            Contact Last Name
                          </span>
                          <p className="font-medium text-gray-800">{values.vendorContactLastName}</p>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600 flex items-center">
                            <IconMail size={16} className="mr-1" />
                            Email
                          </span>
                          <p className="font-medium text-gray-800">{values.vendorContactEmail}</p>
                        </div>
                        <div className="flex flex-col">
                          <span className="text-sm text-gray-600 flex items-center">
                            <IconPhone size={16} className="mr-1" />
                            Phone
                          </span>
                          <p className="font-medium text-gray-800">{values.vendorContactPhoneNumber}</p>
                        </div>
                        <div className="flex flex-col sm:col-span-1">
                          <span className="text-sm text-gray-600 flex items-center">
                            <IconMapPin size={16} className="mr-1" />
                            Address
                          </span>
                          <p className="font-medium text-gray-800">{values.vendorContactCompanyAddress}</p>
                        </div>
                      </div>
                    )}
                  </>
                )}
                {id !== "new" && (
                  <div className="relative">
                    {!values?.financingData?.financingRequest && (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          setConfirmDelete(true);
                        }}
                        className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                      >
                        Delete
                      </button>
                    )}
                  </div>
                )}
              </div>
            </>
          );
        }}
      </FormWrapper>
    </div>
  );
}