import { useState, useEffect } from 'react';
import Modal from './modal'
import Loading from './loading';
import { api, api_post } from '../api.service';
const formatDate = (dateStr) => {
  const d = new Date(dateStr);
  if (isNaN(d)) return "N/A";
  const month = (d.getMonth() + 1).toString().padStart(2, '0');
  const day = d.getDate().toString().padStart(2, '0');
  const year = d.getFullYear().toString().slice(-2);
  return `${month}/${day}/${year}`;
};
const QUICKBOOKS_IMPORT_CONFIG = {
  Vendor: {
    label: 'Vendors',
    fetchEndpoint: '/quickbooks/vendors/fetch',
    importEndpoint: '/quickbooks/vendors/import',
    resultKey: 'vendors',
    bodyKey: 'selectedVendors',
    successMessage: 'Vendors imported successfully!'
  },
  Customer: {
    label: 'Customers',
    fetchEndpoint: '/quickbooks/customers/fetch',
    importEndpoint: '/quickbooks/customers/import',
    resultKey: 'customers',
    bodyKey: 'selectedCustomers',
    successMessage: 'Customers imported successfully!'
  },
  Bill: {
    label: 'Bills',
    fetchEndpoint: '/quickbooks/bills/fetch',
    importEndpoint: '/quickbooks/bills/import',
    resultKey: 'bills',
    bodyKey: 'selectedBills',
    successMessage: 'Bills imported successfully!'
  }
};

export default function QuickbooksImportWizard({ dataTypes = ["Vendor"], onClose }) {
  const [step, setStep] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [fetchedData, setFetchedData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const currentType = dataTypes[currentIndex];
  const config = QUICKBOOKS_IMPORT_CONFIG[currentType];

  useEffect(() => {
    if (!config) {
      setError(`Unsupported data type: ${currentType}`);
      return;
    }
    fetchDataFromQuickbooks();
  }, [currentIndex]);

  const fetchDataFromQuickbooks = async () => {
    try {
      setIsLoading(true);
      setError('');
      setFetchedData([]);
      setSelectedData([]);
      // Example: GET /quickbooks/vendors/fetch
      const data = await api(`${process.env.REACT_APP_API_URL}${config.fetchEndpoint}`);
      const list = data[config.resultKey] || [];
      setFetchedData(list);
      setSelectedData(list.map((item) => ({ ...item, selected: true })));
    } catch (err) {
      setError(err.message || 'Error fetching data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCheckboxChange = (index) => {
    const updated = [...selectedData];
    updated[index].selected = !updated[index].selected;
    setSelectedData(updated);
  };

  const handleImportSelected = async () => {
    try {
      setIsLoading(true);
      setError('');
      const selectedOnly = selectedData.filter((x) => x.selected);
      if (!selectedOnly.length) {
        setError('No data selected to import');
        setIsLoading(false);
        return;
      }
      const bodyPayload = {};
      bodyPayload[config.bodyKey] = selectedOnly;
      const result = await api_post(`${process.env.REACT_APP_API_URL}${config.importEndpoint}`, bodyPayload);

      if (result.error) {
        setError(result.error);
        return;
      }

      setStep(1); // success step for current dataType
    } catch (err) {
      setError(err.message || 'Error importing data');
    } finally {
      setIsLoading(false);
    }
  };

  // Once a type is done (step=1) user can proceed to next type or close
  const handleNextDataType = () => {
    if (currentIndex < dataTypes.length - 1) {
      setStep(0);
      setCurrentIndex(currentIndex + 1);
    } else {
      onClose();
      document.body.classList.remove('overflow-hidden');
    }
  };

  // Render pages
  const renderMainStep = () => {
    return (
      <div className="space-y-4">
        <h2 className="text-xl font-bold">
          QuickBooks {config.label} Import
        </h2>
        {isLoading ? (
          <Loading />
        ) : error ? (
          <div className="text-red-600">{error}</div>
        ) : (
          <>
            <div className="max-h-64 overflow-auto border rounded p-2">
              {selectedData.length ? (
                selectedData.map((item, idx) => (
                  <div key={idx} className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={item.selected}
                      onChange={() => handleCheckboxChange(idx)}
                      className="mr-2"
                    />
                    <div className="text-sm">
                      {currentType === "Bill" ? (
                        <div>
                          <div><strong>Bill ID: {item.DocNumber || item.invoiceNumber || item.Id || 'N/A'}</strong></div>
                          <div>Transaction Date: {item.TxnDate ? formatDate(item.TxnDate) : 'N/A'}</div>
                          <div>Vendor: {item.VendorRef?.name || 'N/A'}</div>
                          <div>Total: ${Number(item.TotalAmt || item.total || 0).toLocaleString()}</div>
                        </div>
                      ) : (
                        <div>
                          <strong>{item.DisplayName || item.name || 'Unnamed'}</strong>
                          <span className="ml-2 text-gray-500 text-xs">
                            {item.PrimaryEmailAddr?.Address || item.email || ''}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="text-gray-600">No {config.label} found.</div>
              )}
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={onClose}
                className="bg-gray-300 px-4 py-2 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleImportSelected}
                className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
                disabled={!selectedData.length}
              >
                Import Selected
              </button>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderSuccessStep = () => {
    return (
      <div className="space-y-4">
        <h2 className="text-xl font-bold">{config.successMessage}</h2>
        <div className="flex justify-end">
          <button
            onClick={handleNextDataType}
            className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded"
          >
            {currentIndex < dataTypes.length - 1 ? 'Next' : 'Close'}
          </button>
        </div>
      </div>
    );
  };

  return (
    <Modal onClose={onClose} darken containerClassName="max-w-lg w-full">
      <div className="p-4 min-h-[200px]">
        {step === 0 ? renderMainStep() : renderSuccessStep()}
      </div>
    </Modal>
  );
}