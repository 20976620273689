import { useState } from 'react';
import QuickbooksImportWizard from './quickbooks-import-wizard';

export default function QuickbooksRefreshButton({ dataTypes = ["Vendor"], buttonText = "Refresh Data from QuickBooks" }) {
  const [showWizard, setShowWizard] = useState(false);
  const onClose = () => {
    setShowWizard(false);
    document.body.classList.remove('overflow-hidden');
    window.location.reload();
  };
  return (
    <>
      <button
        onClick={() => setShowWizard(true)}
        className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded"
      >
        {buttonText}
      </button>
      {showWizard && (
        <QuickbooksImportWizard
          dataTypes={dataTypes}
          onClose={()=>onClose()}
        />
      )}
    </>
  );
}