import React, { useEffect, useState, useCallback } from 'react';
import { usePlaidLink } from 'react-plaid-link';

export default function PlaidOAuthPage() {
  const params = new URLSearchParams(window.location.search);
  const oauthStateId = params.get('oauth_state_id');

  const linkToken = localStorage.getItem('link_token');

  const onSuccess = useCallback((publicToken, metadata) => {
    console.log('OAuth flow completed successfully:', metadata);
    // Optionally, exchange the public token here and redirect as needed
  }, []);

  const onExit = (err, metadata) => {
    console.error('Plaid Link exited with error:', err, metadata);
  };
  
  const isReinitialization = window.location.href.includes('oauth_state_id=');
  
  const config = {
    token: linkToken,
    ...(isReinitialization ? { receivedRedirectUri: window.location.href } : {}),
    onSuccess,
    onExit,
  };

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (ready ) {
      open();
    }
  }, [ready, open ]);

  return <></>;
}