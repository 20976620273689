import { useState, useEffect } from 'react';
import { api } from '../../api.service';
import { toast, ToastContainer } from 'react-toastify';
import { uploadFile } from '../../service/files';

/**
 * FileField component
 * Allows single or multiple file uploads. If multiple is true,
 * we store an array of doc objects/IDs; otherwise a single doc.
 *
 * Props:
 * - label: string
 * - value: string | object | array of strings/objects
 * - onChange: function
 * - className: string
 * - labelClass: string
 * - source: any
 * - hideNote: boolean
 * - disabled: boolean
 * - required: boolean
 * - returnFileObject: boolean
 * - returnFullDoc: boolean
 * - multiple: boolean
 * - icon: node
 */
export default function FileField({
  label,
  value,
  onChange,
  className,
  labelClass,
  source,
  hideNote = false,
  disabled = false,
  required = false,
  returnFileObject = false,
  returnFullDoc = false,
  multiple = false,
  icon = null,
}) {
  // Utility to parse a passed-in value (string docId or object) into a doc object
  const parseDoc = (d) => {
    if (!d) return null;
    if (typeof d === 'string') {
      return { _id: d, key: d };
    } else {
      // assume object with _id/key
      return d;
    }
  };

  // Initialize state from "value":
  // If value is an array -> map each item to doc object
  // Else if single -> store in an array
  const [documents, setDocuments] = useState(() => {
    if (!value) return [];
    if (Array.isArray(value)) {
      return value.map(parseDoc).filter(Boolean);
    }
    return [parseDoc(value)].filter(Boolean);
  });

  const [loading, setIsLoading] = useState(null);

  // Helper to start the loading bar
  const startLoading = () => {
    setIsLoading(0);
    const interval = setInterval(() => {
      setIsLoading((prev) => {
        if (prev === null) {
          clearInterval(interval);
          return null;
        }
        if (prev === 97) {
          clearInterval(interval);
          return 97;
        }
        if (prev === 100) {
          clearInterval(interval);
          return 100;
        }
        return (prev ?? 0) + 1;
      });
    }, 100);
  };

  const finishLoading = () => {
    setIsLoading(100);
    setTimeout(() => {
      setIsLoading(null);
    }, 2000);
  };

  const doSingleUpload = async (file) => {
    const formData = new FormData();
    formData.append('file', file);
    const sourceData = { source };
    formData.append('sourceData', JSON.stringify(sourceData));

    startLoading();
    try {
      const response = await uploadFile(formData);
      finishLoading();
      toast.success('Document uploaded successfully');
      if (returnFullDoc) {
        return { ...response, localFile: file };
      } else if (returnFileObject) {
        return { id: response._id, file };
      } else {
        return { _id: response._id, key: response.key || response._id };
      }
    } catch (error) {
      finishLoading();
      toast.error('Document upload failed');
      return null;
    }
  };

  const handleUploadFiles = async (files) => {
    if (!files || files.length === 0) return;
    let newDocs = multiple ? [...documents] : [];
    for (const file of files) {
      const newDoc = await doSingleUpload(file);
      if (newDoc) {
        newDocs.push(newDoc);
      }
    }
    setDocuments(newDocs);
    if (typeof onChange === 'function') {
      if (multiple) {
        if (returnFullDoc || returnFileObject) {
          onChange(newDocs);
        } else {
          onChange(newDocs.map((d) => d._id));
        }
      } else {
        const doc = newDocs[0] || null;
        if (returnFullDoc || returnFileObject) {
          onChange(doc);
        } else {
          onChange(doc?._id || null);
        }
      }
    }
  };

  // Download a single doc
  const handleDownloadDocument = async (doc) => {
    if (!doc?.key) return;
    try {
      const response = await api(
        `${process.env.REACT_APP_API_URL}/document/download-url?key=${doc.key}`
      );
      if (!response?.url) {
        toast.error('Could not fetch download URL');
        return;
      }
      const link = document.createElement('a');
      link.href = response.url;
      link.setAttribute('target', '_blank');
      link.setAttribute('download', '');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toast.error('Document download failed');
    }
  };

  return (
    <div className={className}>
      <ToastContainer position="top-center" autoClose={2000} />
      {label && (
        <div
          className={`text-sm font-medium leading-6 text-gray-800 ${labelClass}`}
        >
          {label} {required && <span className="text-red-500">*</span>}
        </div>
      )}
      {!label && required && (
        <p className="font-medium text-sm">
          File Upload <span className="text-red-500">*</span>
        </p>
      )}

      {/* Display existing docs as clickable links */}
      {documents.length > 0 && (
        <div className="flex flex-col space-y-1 mt-1">
          {documents.map((doc, idx) => (
            <div
              key={`${doc._id}_${idx}`}
              onClick={() => handleDownloadDocument(doc)}
              className="text-xs font-medium text-left underline cursor-pointer text-gray-700 animate-fade truncate"
              title={doc.key || doc._id}
            >
              {doc.key || doc._id}
            </div>
          ))}
        </div>
      )}

      {/* Loading bar */}
      {loading !== null && (
        <div className="relative w-full h-2 mt-2 bg-neutral-200 rounded animate-fade">
          <div
            className="absolute top-0 left-0 h-2 bg-yellow-500 rounded"
            style={{ width: `${loading}%` }}
          ></div>
        </div>
      )}

      {/* Upload input (disabled if needed) */}
      {!disabled && (
        <label className="text-sm font-medium text-center text-gray-800 mt-2">
          <input
            onChange={(e) => {
              // handle multiple files
              const files = e.target.files;
              if (files?.length) {
                handleUploadFiles(files);
              }
              e.target.value = null;
            }}
            type="file"
            multiple={multiple}
            name="file"
            className="hidden"
            required={required && documents.length === 0}
          />
          <div
            role="button"
            className="w-full p-2 text-sm font-medium text-center bg-gray-200 rounded shadow cursor-pointer text-gray-700 hover:scale-105 hover:bg-gray-300 border hover:text-gray-900 transition-all flex flex-row items-center justify-center"
          >
            {icon}
            <span>
              {documents.length > 0
                ? multiple
                  ? 'Add More Files'
                  : 'Replace File'
                : 'Upload File'}
            </span>
          </div>
        </label>
      )}

      {documents.length > 0 && !hideNote && (
        <div>
          <p className="text-xs opacity-50 mt-1">
            Note - please submit the form to save the file(s).
          </p>
        </div>
      )}
    </div>
  );
}