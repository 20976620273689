import { ArrowDownIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ArrowsUpDownIcon, ArrowUpIcon } from '@heroicons/react/24/outline';
import '@lendica/ibranchtrigger';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { api } from './api.service';
import Button from './components/button';
import HelpPopup from './components/help-popup';
import SelectField from './forms/fields/select-field';
import { ExportToCsv } from 'export-to-csv';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'

export default function Projects() {
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [total, setTotal] = useState();
  const [showMore, setShowMore] = useState(false);
  const [sortAlg, setSortAlg] = useState({ param: '', isAscending: true });
  const [projectStatusFilter, setProjectStatusFilter] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const [projectsList, setProjectsList] = useState([]);

  useEffect(() => {
    api(`${process.env.REACT_APP_API_URL}/app`).then((x) => {
      setData(x);
    });
  }, []);

  useEffect(() => {
    let total = 0;
    let owed = 0;
    let paid = 0;
    let totalSpend = 0;
    let totalEstimatedSpend = 0;

    if (data?.projects?.length) {
      for (let p of data.projects) {
        let totals = getItemTotals(p._id, p.deposit);

        if (p.totalPrice) total += +p.totalPrice;
        if (totals.totalPaidToDate) paid += totals.totalPaidToDate;
        if (totals.totalOutstanding) owed += totals.totalOutstanding;
        if (totals.totalCosts) totalSpend += totals.totalCosts;
        if (p.totalEstimatedCosts) totalEstimatedSpend += +p.totalEstimatedCosts;
      }
    }

    let percentageComplete = (totalSpend / totalEstimatedSpend) * 100;
    if (isNaN(percentageComplete)) percentageComplete = 0;
    percentageComplete = Math.round(percentageComplete);

    setTotal({
      total,
      owed,
      paid,
      totalSpend,
      totalEstimatedSpend,
      percentageComplete,
    });
  }, [data]);

  const linksRef = useRef();
  const linksVisible = useIntersectionObserver(linksRef, {
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    if (linksVisible) {
      linksRef.current.classList.remove('invisible');
      linksRef.current.classList.add('animate-fade-up');
    }
  }, [linksVisible]);

  const contentRef = useRef();
  const contentVisible = useIntersectionObserver(contentRef, {
    rootMargin: '0px',
    threshold: 0.2,
  });
  useEffect(() => {
    if (contentVisible) {
      contentRef.current.classList.remove('invisible');
      contentRef.current.classList.add('animate-fade-up');
    }
  }, [contentVisible]);

  const getItemTotals = (projectId, depositPaid) => {
    let totalOutstanding = 0;
    let totalPaidToDate = +(depositPaid ?? 0);
    let totalCosts = 0;
    for (let i of data.invoices.filter((x) => x.project === projectId)) {
      if (i.paid) {
        totalPaidToDate += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      } else {
        totalOutstanding += +(i.tax ?? 0) + +(i.subtotal ?? 0);
      }
    }
    for (let b of data.bills.filter((x) => x.project === projectId)) {
      totalCosts += +(b.tax ?? 0) + +(b.subtotal ?? 0);
    }
    return { totalOutstanding, totalPaidToDate, totalCosts };
  };

  useEffect(() => {
    setProjectsList(
      data?.projects
        ?.filter((x) => !projectStatusFilter || x.status === projectStatusFilter)
        .filter((x) =>
          !searchTerm
            ? true
            : x.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => sortFn(a, b, sortAlg))
        .map((x) => ({
          _id: x._id,
          project_name: x.name,
          project_status: x.status,
          total_contract_value: safeCurrency(x.totalPrice),
          total_paid_to_date: safeCurrency(
            getItemTotals(x._id, x.deposit).totalPaidToDate
          ),
          total_outstanding: safeCurrency(
            getItemTotals(x._id, x.deposit).totalOutstanding
          ),
          total_costs_to_date: safeCurrency(
            getItemTotals(x._id, x.deposit).totalCosts
          ),
          percentage_completed: `${safeNumber(
            (getItemTotals(x._id, x.deposit).totalCosts /
              x.totalEstimatedCosts) *
            100
          )}%`,
        })) ?? []
    );
  }, [data, projectStatusFilter, sortAlg, searchTerm]);

  const exportToCsv = () => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      headers: Object.keys(projectsList[0] ?? {}),
    };

    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(projectsList);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div>
        <div className="w-full max-w-5xl p-5 mx-auto sm:p-0">
          <div className="text-4xl font-semibold text-gray-800">
            Projects
          </div>

          {data && (
            <>
              {!data?.projects.length && (
                <div className="border flex justify-center rounded shadow bg-primary animate-fade-up animate-delay-300">
                  <Empty />
                </div>
              )}

              {data?.projects.length > 0 && (
                <>
                  {/* Filters row */}
                  <div className="flex flex-col items-stretch gap-2 mb-3 mt-1 sm:items-end sm:flex-row sm:justify-between">
                    <div className="w-1/2 mr-auto">
                      <label
                        htmlFor="search"
                        className="block text-sm font-medium text-gray-700 mb-1"
                      >
                        Search by Project Name
                      </label>
                      <div className="relative">
                        <MagnifyingGlassIcon
                          className="
                            absolute 
                            left-2 
                            top-1/2 
                            transform 
                            -translate-y-1/2 
                            w-5 
                            h-5 
                            text-gray-500 
                            pointer-events-none
                          "
                        />
                        <input
                          id="search"
                          type="text"
                          className="
                            block
                            w-full 
                            text-sm 
                            bg-primary 
                            border 
                            rounded-none     
                            pl-9             
                            pr-3
                            py-2
                          "
                          placeholder="Type a project name..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="w-1/4 mr-auto">
                      <SelectField
                        label="Project Status Filter"
                        value={projectStatusFilter}
                        onChange={setProjectStatusFilter}
                        options={[
                          { text: 'All', value: '' },
                          { text: 'In Review', value: 'In Review' },
                          { text: 'Active', value: 'Active' },
                          { text: 'Complete', value: 'Complete' },
                        ]}
                      />
                    </div>

                    <Button className="w-full md:!w-64 text-center !bg-primary !hover:bg-gray-500 rounded-full border-2 !border-gray-200 text-gray-900 text-xs" text={<><ArrowDownIcon className="w-4 mr-1" />Download CSV</>} onClick={() => exportToCsv()} />
                    <Button className="w-full md:!w-64 text-center !bg-primary !hover:bg-gray-500 rounded-full border-2 !border-yellow-500 !text-gray-900" text={<><PlusIcon className="w-4 mr-1" /> Add Project</>} onClick={() => navigate('/project/create')} />
                  </div>

                  <div
                    className="
                      border 
                      grid 
                      grid-cols-4 
                      rounded 
                      shadow 
                      lg:grid-cols-6 
                      bg-primary 
                      animate-fade-up 
                      animate-delay-300
                    "
                  >
                    {/* Header Row */}
                    <div className="hidden p-5 font-semibold tracking-tight border-b text-gray-800 lg:block ">
                      <SortToggle
                        text="Project Name"
                        accessor="name"
                        state={sortAlg}
                        onChange={(next) => setSortAlg(next)}
                      />
                    </div>
                    <div className="hidden p-5 font-semibold tracking-tight border-b text-gray-800 lg:block ">
                      <SortToggle
                        text="Project Status"
                        accessor="status"
                        state={sortAlg}
                        onChange={(next) => setSortAlg(next)}
                      />
                    </div>
                    <div className="hidden p-5 font-semibold tracking-tight text-right border-b text-gray-800 lg:block ">
                      <SortToggle
                        text="Contract Value"
                        accessor="totalPrice"
                        state={sortAlg}
                        onChange={(next) => setSortAlg(next)}
                      />
                    </div>
                    <div className="hidden p-5 font-semibold tracking-tight text-right border-b text-gray-800 lg:block ">
                      <SortToggle
                        text="Paid To Date"
                        accessor="paidToDate"
                        state={sortAlg}
                        onChange={(next) => setSortAlg(next)}
                      />
                    </div>
                    <div className="hidden p-5 font-semibold tracking-tight text-right border-b text-gray-800 lg:block ">
                      <SortToggle
                        text="Costs To Date"
                        accessor={(row) =>
                          row.getItemTotals
                            ? row.getItemTotals(row._id, row.deposit).totalCosts
                            : undefined
                        }
                        state={sortAlg}
                        onChange={(next) => setSortAlg(next)}
                      />
                    </div>
                    <div className="relative hidden p-5 font-semibold tracking-tight text-right border-b text-gray-800 lg:block ">
                      <div className="absolute right-16 top-5">
                        <HelpPopup text="Total costs to date/total estimated costs" />
                      </div>
                      <SortToggle
                        text="Percentage Complete"
                        accessor={(row) =>
                          row.costsIncurredToDate &&
                            row.totalEstimatedCosts
                            ? (row.costsIncurredToDate /
                              row.totalEstimatedCosts) *
                            100
                            : undefined
                        }
                        state={sortAlg}
                        onChange={(next) => setSortAlg(next)}
                      />
                    </div>

                    {/* Rows */}
                    {projectsList
                      ?.filter((_, i) => (showMore ? true : i < 10))
                      .map((x) => (
                        <Row
                          key={x._id}
                          project={x}
                          onClick={() => navigate('/project/' + x._id)}
                        />
                      ))}

                    {/* Totals */}
                    <TotalsRow total={total} navigate={navigate} setShowMore={setShowMore} />
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

/**
 * Single project row
 */
function Row({ project, onClick }) {
  return (
    <>
      {/* NAME (col 1 on large, col-span-2 on small) */}
      <div
        onClick={onClick}
        className="
          p-5 
          font-medium 
          tracking-wide 
          border-b 
          cursor-pointer 
          text-gray-800 
          col-span-2 
          lg:col-span-1 
          hover:underline 
          text-sm 
          text-sm
        "
      >
        {project.project_name}
      </div>

      {/* STATUS (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-medium 
          border-b 
          text-gray-800 
          lg:block 
          text-sm 
          text-sm
        "
      >
        {project.project_status}
      </div>

      {/* CONTRACT VALUE (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-medium 
          text-right 
          border-b 
          text-gray-800 
          lg:block 
          text-sm 
          text-sm
        "
      >
        {project.total_contract_value}
      </div>

      {/* PAID TO DATE (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-medium 
          text-right 
          border-b 
          text-gray-800 
          lg:block 
          text-sm 
          text-sm
        "
      >
        {project.total_paid_to_date}
      </div>

      {/* COSTS TO DATE (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-medium 
          text-right 
          border-b 
          text-gray-800 
          lg:block 
          text-sm 
          text-sm
        "
      >
        {project.total_costs_to_date}
      </div>

      {/* PERCENT COMPLETE (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-medium 
          text-right 
          border-b 
          text-gray-800 
          lg:block 
          text-sm 
          text-sm
        "
      >
        {project.percentage_completed}
      </div>

      {/* MOBILE-ONLY summary (spans remaining 2 columns on small) */}
      <div
        className="
          col-span-2 
          p-5 
          border-b 
          text-sm 
          lg:hidden
        "
      >
        <div className="text-xs text-gray-800">
          Paid To Date -{' '}
          <span className="text-sm font-medium text-gray-800">
            {project.total_paid_to_date}
          </span>
        </div>
        <div className="text-xs text-gray-800">
          Contract Value -{' '}
          <span className="text-sm font-medium text-gray-800">
            {project.total_contract_value}
          </span>
        </div>
        <div className="text-xs text-gray-800">
          Costs To Date -{' '}
          <span className="text-sm font-medium text-gray-800">
            {project.total_costs_to_date}
          </span>
        </div>
        <div className="text-xs text-gray-800">
          Percent Complete -{' '}
          <span className="text-sm font-medium text-gray-800">
            {project.percentage_completed}
          </span>
        </div>
      </div>
    </>
  );
}

/**
 * Totals row
 */
function TotalsRow({ total, navigate, setShowMore }) {
  return (
    <>
      {/* New Project button (left side on large screens) */}
      <div className="hidden px-5 pt-3 text-gray-200 lg:block border-slate-500">
        <Button
          className="rounded-full !bg-gray-100 border !border-gray-200 hover:bg-gray-300 !text-sm !font-medium"
          onClick={() => setShowMore(true)}
          text="...Show More"
        />
      </div>

      {/* Totals label (1 column on large, 2 columns on small) */}
      <div
        className="
          p-5 
          text-lg 
          font-semibold 
          leading-6 
          tracking-wide 
          text-right 
          text-gray-800 
          col-span-2 
          lg:col-span-1 
          border-slate-500
        "
      >
        Totals:
      </div>

      {/* Total Contract Value (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-semibold 
          text-right 
          text-gray-800 
          lg:block 
          border-slate-500
        "
      >
        ${total?.total?.toLocaleString() ?? '0'}
      </div>

      {/* Total Paid (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-semibold 
          text-right 
          text-gray-800 
          lg:block 
          border-slate-500
        "
      >
        ${total?.paid?.toLocaleString() ?? '0'}
      </div>

      {/* Total Spend (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-semibold 
          text-right 
          text-gray-800 
          lg:block 
          border-slate-500
        "
      >
        ${total?.totalSpend?.toLocaleString() ?? '0'}
      </div>

      {/* % Complete (hidden on small) */}
      <div
        className="
          hidden 
          p-5 
          font-semibold 
          text-right 
          text-gray-800 
          lg:block 
          border-slate-500
        "
      >
        {Math.min(total?.percentageComplete || 0, 100)}%
      </div>

      {/* Mobile-only totals summary (span 2 columns on small) */}
      <div
        className="
          col-span-2 
          p-5 
          border-slate-500 
          lg:hidden
        "
      >
        <div className="text-xs text-gray-800">
          Paid To Date -{' '}
          <span className="text-sm font-medium text-gray-800">
            ${total?.paid?.toLocaleString() ?? '0'}
          </span>
        </div>
        <div className="text-xs text-gray-800">
          Contract Value -{' '}
          <span className="text-sm font-medium text-gray-800">
            ${total?.total?.toLocaleString() ?? '0'}
          </span>
        </div>
        <div className="text-xs text-gray-800">
          Costs To Date -{' '}
          <span className="text-sm font-medium text-gray-800">
            ${total?.totalSpend?.toLocaleString() ?? '0'}
          </span>
        </div>
        <div className="text-xs text-gray-800">
          Percent Complete -{' '}
          <span className="text-sm font-medium text-gray-800">
            {Math.min(total?.percentageComplete || 0, 100)}%
          </span>
        </div>
      </div>

      {/* New Project button (at bottom on small screens, spanning 4 columns) */}
      <div
        className="
          col-span-4 
          p-5 
          text-gray-200 
          border-t-2 
          lg:hidden 
          border-slate-500
        "
      >
        <Button
          onClick={() => navigate('/project/new')}
          text={
            <>
              <PlusIcon className="w-8 h-8" /> Project
            </>
          }
        />
      </div>
    </>
  );
}

/**
 * Empty State
 */
function Empty() {
  const navigate = useNavigate();
  return (
    <div className="my-10 text-center">
      <svg
        className="w-12 h-12 mx-auto text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-semibold text-gray-800">No projects</h3>
      <p className="mt-1 text-sm text-gray-700">
        Get started by creating a new project.
      </p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/project/new')}
          type="button"
          className="inline-flex items-center px-3 py-2 text-sm font-semibold bg-yellow-500 border-2 border-yellow-500 rounded-md text-gray-800"
        >
          <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
          New Project
        </button>
      </div>
    </div>
  );
}

/**
 * Sort toggle component
 */
function SortToggle({
  text,
  accessor,
  state,
  onChange,
  className,
  defaultAscending = true,
}) {
  return (
    <div
      className={`flex justify-center w-full h-full items-center text-center cursor-pointer select-none ${className}`}
      onClick={() =>
        onChange({
          accessor,
          isAscending: isCurrentAccessor(accessor, state.accessor)
            ? !state.isAscending
            : defaultAscending,
        })
      }
    >
      {text}
      <div className="flex items-center justify-center w-6 h-6 ml-1 min-w-6 min-h-6">
        <ArrowUpIcon
          className={`${isCurrentAccessor(accessor, state.accessor)
            ? 'h-6 w-6 opacity-100'
            : 'h-0 w-0 opacity-0'
            } ${state.isAscending ? 'rotate-0' : 'rotate-180'} transition-all`}
        />
        <ArrowsUpDownIcon
          className={`${isCurrentAccessor(accessor, state.accessor)
            ? 'h-0 w-0 opacity-0'
            : 'h-5 w-5 opacity-100'
            } text-gray-400 transition-all`}
        />
      </div>
    </div>
  );
}

function isCurrentAccessor(source, target) {
  if (typeof source !== typeof target) {
    return false;
  }
  if (typeof source === 'function') {
    return source.toString() === target.toString();
  }
  return source === target;
}

function sortFn(a, b, { accessor, isAscending }) {
  let valA, valB;
  if (typeof accessor === 'function') {
    valA = accessor(a);
    valB = accessor(b);
  } else {
    valA = a[accessor];
    valB = b[accessor];
  }

  if (valA === undefined) return valB === undefined ? 0 : 1;
  if (valB === undefined) return -1;

  // Note: In your original code, the sorting multiplier was reversed (valA > valB ? 1 : -1) * (isAscending ? -1 : 1).
  // Adjust if you need ascending/descending behavior reversed:
  return (valA > valB ? 1 : -1) * (isAscending ? -1 : 1);
}

function safeCurrency(value) {
  if (value === 0) {
    return '$0';
  }
  if (!value) {
    return '-';
  }
  return '$' + Number(value).toLocaleString();
}

function safeNumber(value) {
  if (value === 0) {
    return '0';
  }
  if (!value) {
    return '-';
  }
  return Number(value).toLocaleString();
}

function useIntersectionObserver(ref, options) {
  const [isIntersecting, setIntersecting] = useState(false);
  const observer = useRef();

  useEffect(() => {
    observer.current = new IntersectionObserver(([entry]) => {
      setIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.current.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.current.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return isIntersecting;
}
