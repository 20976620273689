import React, { useState, useEffect } from 'react';
import Modal from './modal';
import { api, api_post, api_update } from '../api.service';
import Loading from './loading';
import logo from '../logo.png';
import { useNavigate } from 'react-router-dom';
import FilePreviewWithMagnification from './file-preview-with-magnification';
import Button from './button';
import { renderCurrency } from '../utils';

export default function BillFinancingModal({
    billData,
    onClose,
    currentStep,
    setCurrentStep,
    setBillData,
    creditApplication,
    setCreditApplication,
    loading,
    setLoading,
    financingRequest,
    setFinancingRequest,
    project
}) {
    const [vendorACH, setVendorACH] = useState({
        bankName: '',
        routingNumber: '',
        accountNumber: '',
        accountType: 'Checking',
    });

    const [billPreviewUrl, setBillPreviewUrl] = useState(null);

    useEffect(() => {
        if (currentStep === 2 && billData?.document?.key) {
            console.log('Bill doc key found, fetching preview URL...');
            api(`${process.env.REACT_APP_API_URL}/document/download-url?key=${billData.document.key}`)
                .then((res) => {
                    if (res?.url) {
                        setBillPreviewUrl(res.url);
                    }
                })
                .catch((err) => {
                    console.error('Error fetching Bill doc preview URL', err);
                });
        } else {
            setBillPreviewUrl(null);
        }
    }, [currentStep, billData]);

    const [offers, setOffers] = useState(financingRequest?.offers || []);
    const [selectedOfferIndex, setSelectedOfferIndex] = useState(null);
    const [visibleDetails, setVisibleDetails] = useState({});
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [paymentScheduleExpanded, setPaymentScheduleExpanded] = useState(false);

    const generatePaymentSchedule = (offer) => {
        const schedule = [];
        const currentDate = new Date();
        for (let i = 0; i < offer.termLength; i++) {
            const paymentDate = new Date(currentDate);
            paymentDate.setDate(paymentDate.getDate() + (i + 1) * 7);
            schedule.push({
                paymentNumber: i + 1,
                date: paymentDate,
                amount: offer.periodicPaymentAmount
            });
        }
        return schedule;
    };
    const navigate = useNavigate();

    const handleSubmitACH = async () => {
        try {
            const achInfo = await api_post(`${process.env.REACT_APP_API_URL}/ach-info/`, {
                vendorId: billData.vendor,
                bankName: vendorACH.bankName,
                routingNumber: vendorACH.routingNumber,
                accountNumber: vendorACH.accountNumber,
                accountType: vendorACH.accountType,
                holderName: billData.vendorName,
                owner: billData.vendor,
                type: 'vendor',
            });

            const financingRequestPayload = {
                invoice: billData._id,
                achInfo: achInfo._id,
                project: billData?.project
            };

            const financingRequestResponse = await api_post(`${process.env.REACT_APP_API_URL}/financing-request/`, financingRequestPayload);

            setFinancingRequest(financingRequestResponse);
            console.log("Financing Request", financingRequestResponse);

            const newBillData = {
                ...billData,
                financingData: {
                    financingRequest: financingRequestResponse._id,
                    achInfo: achInfo._id,
                    program: "Pay Later",
                    partner: "BuildrFi",
                },
            };

            await api_update(`${process.env.REACT_APP_API_URL}/app/bill/${billData._id}`, {
                ...newBillData,
            });

            setBillData(newBillData);
            setCurrentStep(3);
        } catch (error) {
            console.error('Error creating financing request:', error);
        }
    };

    const renderBillSummary = () => (
        <div className="bg-primary border rounded-lg px-4 pt-1 flex flex-row-reverse w-3/5 mr-10 gap-x-4">
            <div className="flex flex-col w-1/2">
                <h3 className="text-lg font-semibold mb-4">Bill Summary</h3>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <strong>Invoice Number:</strong>
                    </div>
                    <div>{billData.invoiceNumber || 'N/A'}</div>
                    <div>
                        <strong>Vendor Name:</strong>
                    </div>
                    <div>{billData.vendorName || 'N/A'}</div>
                    <div>
                        <strong>Subtotal:</strong>
                    </div>
                    <div>${Number(billData.subtotal).toFixed(2)}</div>
                    <div>
                        <strong>Tax:</strong>
                    </div>
                    <div>${Number(billData.tax).toFixed(2)}</div>
                    <div>
                        <strong>Total:</strong>
                    </div>
                    <div>${Number(billData.subtotal + billData.tax).toFixed(2)}</div>
                </div>
            </div>

            {billPreviewUrl && (
                <div className="w-1/2 h-full">
                    <strong>File Preview:</strong>
                    <div className="w-full h-full mt-2">
                        <FilePreviewWithMagnification
                            fileUrl={billPreviewUrl}
                            previewLabel="Preview (click to enlarge)"
                            placeholderText="No file to preview"
                            docName={billData.document.key}
                            className="h-full"
                        />
                    </div>
                </div>
            )}
        </div>
    );

    const renderStepContent = () => {
        switch (currentStep) {
            case 1:
                return (
                    <div className="text-center">
                        {creditApplication?.status === 'pending' ? (
                            <>
                                <h2 className="text-3xl font-bold mb-4">
                                    Your application is pending verification.
                                </h2>
                                <p className="text-lg">
                                    We will approve your application within <strong>48 hours</strong> of your submission.
                                </p>
                            </>
                        ) : creditApplication?.status !== 'verified' ? (
                            <>
                                <h2 className="text-3xl font-bold mb-4">
                                    Your application is not complete
                                </h2>
                                <p className="text-lg">
                                    Please ensure your application is complete and try again.
                                </p>
                                <button
                                    className="px-12 py-3 text-lg bg-yellow-500 rounded-md hover:bg-yellow-600 font-semibold mt-8 hover:scale-105 transition-all"
                                    onClick={() => {
                                        navigate(`/financing`);
                                    }}
                                >
                                    Go Back
                                </button>
                            </>
                        ) : (
                            <>
                                <h2 className="text-3xl font-bold mb-4">
                                    Your bill is incomplete
                                </h2>
                                <p className="text-lg">
                                    Please ensure this bill has all required information, and try again.
                                </p>
                            </>
                        )}
                    </div>
                );
            case 2:
                return (
                    <div className="flex flex-row w-full justify-center items-stretch">
                        {renderBillSummary()}
                        <div className='w-1/4' >
                            <p className="text-lg mb-4 text-center">
                                Please enter the vendor's ACH information to proceed.
                            </p>
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSubmitACH();
                                }}
                            >
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">
                                        Bank Name
                                    </label>
                                    <input
                                        type="text"
                                        value={vendorACH.bankName}
                                        onChange={(e) =>
                                            setVendorACH({
                                                ...vendorACH,
                                                bankName: e.target.value,
                                            })
                                        }
                                        required
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                                    />
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">Account Number</label>
                                    <input
                                        type="text"
                                        value={vendorACH.accountNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,12}$/.test(value)) {
                                                setVendorACH({
                                                    ...vendorACH,
                                                    accountNumber: value,
                                                });
                                            }
                                        }}
                                        required
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                                    />
                                    {!/^\d{8,12}$/.test(vendorACH.accountNumber) && vendorACH.accountNumber && (
                                        <p className="text-red-600 text-sm">Account Number must be 8-12 digits.</p>
                                    )}
                                </div>
                                <div className="mb-4">
                                    <label className="block text-sm font-medium">Routing Number</label>
                                    <input
                                        type="text"
                                        value={vendorACH.routingNumber}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,9}$/.test(value)) {
                                                setVendorACH({
                                                    ...vendorACH,
                                                    routingNumber: value,
                                                });
                                            }
                                        }}
                                        required
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm bg-primary"
                                    />
                                    {!/^\d{9}$/.test(vendorACH.routingNumber) && vendorACH.routingNumber && (
                                        <p className="text-red-600 text-sm">Routing Number must be exactly 9 digits.</p>
                                    )}
                                </div>
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded hover:bg-blue-700"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                );
            case 3:
                return (
                    <div className="text-center">
                        <h2 className="text-3xl font-bold mb-4">
                            Thank you for your submission!
                        </h2>
                        <p className="text-lg">
                            We will email you when your offers are ready.
                        </p>
                        <div className="flex justify-center mt-6">
                            <button
                                onClick={() => {
                                    setCurrentStep(4);
                                }}
                                className="px-4 py-2 text-sm font-semibold text-white bg-blue-600 rounded hover:bg-blue-700"
                            >
                                Proceed to Offers
                            </button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div>
                        <div className='grid grid-cols-4 mb-4 border-b border-gray-200 pb-4 px-4'>
                            {/* Project Name */}
                            <div >
                                <p className="text-sm text-gray-500">Project:</p>
                                <p className="text-lg font-medium">{project?.name}</p>
                            </div>

                            {/* Bill # */}
                            <div >
                                <p className="text-sm text-gray-500">Bill #</p>
                                <p className="text-lg font-medium">{billData?.invoiceNumber}</p>
                            </div>

                            {/* Vendor Name */}
                            <div >
                                <p className="text-sm text-gray-500">Vendor:</p>
                                <p className="text-lg font-medium">{billData?.vendorName}</p>
                            </div>

                            {/* Total Amount */}
                            <div >
                                <p className="text-sm text-gray-500">Total Amount:</p>
                                <p className="text-lg font-medium">{renderCurrency(billData?.subtotal + billData?.tax)}</p>
                            </div>
                        </div>
                        <h2 className="text-xl font-bold mb-4 text-center">
                            Your Offers
                        </h2>
                        {loading ? (
                            <p className="text-center">Loading offers...</p>
                        ) : offers && offers.length > 0 ? (
                            <div className=''>
                                <ul className="mb-4 flex flex-col w-full justify-center gap-2">
                                    {offers.map((offer, index) => (
                                        <li
                                            key={index}
                                            className={`flex flex-col mb-4 border-2 p-4 rounded-lg gap-y-2 bg-primary shadow-sm ${selectedOfferIndex === index ? 'border-yellow-500' : ''}`}

                                        >
                                            <div className="grid grid-cols-5 justify-end items-center">
                                                <div className='flex flex-row items-center gap-x-1'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                                    <div className="flex flex-col ml-2">
                                                        <p className="text-xl font-medium">{offer.termLength * 7} days</p>
                                                        <p className="text-sm text-gray-500">Term</p>
                                                    </div>
                                                </div>
                                                <div className='flex flex-col gap-x-4'>
                                                    <p className='text-xl font-medium'>{(offer.interestRate * 100).toFixed(2)}%</p>
                                                    <p className="text-sm text-gray-500">Rate</p>
                                                </div>
                                                <div className='flex flex-col gap-x-4'>
                                                    <p className='text-xl font-medium'>{renderCurrency(offer.periodicPaymentAmount)}</p>
                                                    <p className="text-sm text-gray-500">Weekly Payment</p>
                                                </div>
                                                <div className='flex flex-col gap-x-4'>
                                                    <div className="flex flex-row overflow-hidden">
                                                        <p className="text-xl font-medium mr-1">{offer.termLength}</p>
                                                        {/* Create termLength number of black squares */}
                                                        {offer.termLength > 0 &&
                                                            Array.from({ length: offer.termLength }, (_, index) => (
                                                                <div key={index} className="w-2 h-2 bg-gray-800 ml-1 rounded-sm mt-auto mb-2" />
                                                            ))
                                                        }
                                                    </div>
                                                    <p className="text-sm text-gray-500">Number of Payments</p>
                                                </div>
                                                <div className='flex flex-col gap-y-2'>
                                                    <Button text="Details" className="w-full !bg-primary !border-gray-200 !text-gray-800" onClick={() => setVisibleDetails(prev => ({ ...prev, [index]: !prev[index] }))} />
                                                    <Button text="Select" className="w-full !bg-gray-900 text-primary" onClick={() => setSelectedOfferIndex(index)} />
                                                </div>
                                            </div>
                                            {visibleDetails[index] && (
                                                <div className="grid grid-cols-2 items-center justify-start border bg-gray-100 p-4 rounded-lg gap-x-16">
                                                    {/* amount due today */}
                                                    <div className='flex flex-row items-center'>
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 mr-2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                                                        </svg>
                                                        <div>
                                                            <p className="text-sm">Amount Due Today</p>
                                                            <p className='text-xl font-medium'>{renderCurrency((Number(offer.processingAmount) + Number(offer.depositAmount)))}</p>
                                                            <p className="text-sm text-gray-500">Processing fee ({renderCurrency(offer.processingAmount)}) + initial deposit</p>
                                                        </div>
                                                    </div>
                                                    {/* total cost */}
                                                    <div className='flex flex-col gap-x-4'>
                                                        <p className="text-sm ">Total Payback Amount</p>
                                                        <p className='text-xl font-medium'>{renderCurrency(offer.totalPaybackAmount)}</p>
                                                        <p className="text-sm text-gray-500">{offer.termLength} payments of {renderCurrency(offer.periodicPaymentAmount)}</p>
                                                    </div>
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                                {selectedOfferIndex !== null && (
                                    <div className="text-center">
                                        <Button
                                            onClick={() => setCurrentStep(5)}
                                            className=""
                                            text="Continue with Selected Offer"
                                        />
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="text-center">
                                <p className="mb-4">
                                    You are currently waiting for offers.
                                </p>
                                <p>
                                    We will notify you as soon as offers are available.
                                </p>
                            </div>
                        )}
                    </div>
                );
            case 5:
                const selectedOffer = selectedOfferIndex !== null ? offers[selectedOfferIndex] : null;
                const paymentSchedule = selectedOffer ? generatePaymentSchedule(selectedOffer) : [];

                return (
                    <div className="flex flex-col w-full h-full">
                        <h2 className="text-xl font-bold mb-4">Verify Payment Details</h2>
                        {selectedOffer ? (
                            <>
                                {/* Project & Vendor Info */}
                                <div className="flex flex-wrap mb-4 border border-gray-200 rounded-md p-4">
                                    <div className="w-1/3 mb-2">
                                        <p className="text-sm text-gray-500">Project</p>
                                        <p className="text-base font-medium">{project?.name}</p>
                                    </div>
                                    <div className="w-1/3 mb-2">
                                        <p className="text-sm text-gray-500">Vendor</p>
                                        <p className="text-base font-medium">{billData?.vendorName}</p>
                                    </div>
                                    <div className="w-1/3 mb-2">
                                        <p className="text-sm text-gray-500">Term Length</p>
                                        <p className="text-base font-medium">{selectedOffer.termLength * 7} days</p>
                                    </div>
                                    <div className="w-1/3 mb-2">
                                        <p className="text-sm text-gray-500">Weekly Payment</p>
                                        <p className="text-base font-medium">{renderCurrency(selectedOffer.periodicPaymentAmount)}</p>
                                    </div>

                                    <div className="w-1/3 mb-2">
                                        <p className="text-sm text-gray-500">Total</p>
                                        <p className="text-base font-medium">{renderCurrency(selectedOffer.totalPaybackAmount)}</p>
                                    </div>
                                    <div className="w-1/3 mb-2">
                                        <p className="text-sm text-gray-500">Number of Payments</p>
                                        <p className="text-base font-medium">{selectedOffer.termLength}</p>
                                    </div>
                                </div>

                                {/* Payment Schedule */}
                                <div className="mt-4 border border-gray-200 rounded-md p-4">
                                    <div className="flex justify-between items-center cursor-pointer" onClick={() => setPaymentScheduleExpanded(!paymentScheduleExpanded)}>
                                        <h3 className="text-lg font-semibold mb-2">Payment Schedule</h3>
                                        <span className="text-sm text-blue-600">
                                            {paymentScheduleExpanded ? "Hide" : "Show"}
                                        </span>
                                    </div>
                                    {paymentScheduleExpanded && (
                                        <>
                                            <p className="text-sm text-gray-700 mb-2">
                                                Review your payment schedule. Funds will be sent to {billData?.vendorName} immediately upon confirmation.
                                            </p>
                                            {paymentSchedule.length > 0 ? (
                                                <div className="flex flex-col gap-y-1">
                                                    {paymentSchedule.map((pay) => {
                                                        const formattedDate = new Intl.DateTimeFormat('en-US', {
                                                            month: 'short',
                                                            day: 'numeric',
                                                            year: 'numeric'
                                                        }).format(pay.date);
                                                        return (
                                                            <div
                                                                key={pay.paymentNumber}
                                                                className="flex justify-between border rounded p-1 items-center"
                                                            >
                                                                <span className="font-semibold mr-2 text-yellow-700 bg-yellow-100 rounded-full w-5 h-5 text-center text-xs grid place-content-center">{pay.paymentNumber}</span>
                                                                <span className="mr-auto ml-2 text-xs text-gray-800 font-medium">{formattedDate}</span>
                                                                <span className="font-medium text-xs">{renderCurrency(pay.amount)}</span>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            ) : (
                                                <p className="text-center">No schedule available.</p>
                                            )}
                                        </>
                                    )}
                                    {/* Vendor Payment Confirmation */}
                                    <div className="mt-2 bg-yellow-200 text-yellow-700 rounded-md p-4 flex flex-row gap-x-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 mt-1 mb-auto">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.745 3.745 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                                        </svg>
                                        <div>
                                            <h3 className="font-semibold mb-1">Vendor Payment Confirmation</h3>
                                            <p className="text-sm text-gray-900 opacity-75">
                                                By accepting this offer, you confirm that BuildrFi will send payment to {billData?.vendorName} within 24 hours of confirmation. This ensures your materials are secured promptly.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Terms and Conditions */}
                                <div className="mt-4 select-none">
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input
                                            type="checkbox"
                                            className="form-checkbox h-5 w-5 text-blue-600"
                                            checked={termsAccepted}
                                            onChange={() => setTermsAccepted(!termsAccepted)}
                                        />
                                        <span className="ml-4 font-medium">
                                            I agree to the Terms and Conditions.
                                            <p className='text-sm font-normal' >I agree to BuildrFi's <a href="https://www.buildrfi.com/terms-of-service" target="_blank" rel="noreferrer">Terms and Conditions</a> and authorize ACH debits for financing payments, including reattempts, until revoked in writing.</p>
                                        </span>
                                    </label>
                                </div>

                                {/* Confirm and Accept Button */}
                                <div className="mt-6 flex flex-row w-full">
                                    <Button
                                        onClick={() => setCurrentStep(4)}
                                        text="Back to Offers"
                                        className="!bg-gray-300 hover:!bg-gray-400 text-gray-900 font-medium py-1 px-3 rounded"
                                    />
                                    <Button
                                        onClick={() => handleConfirmOffer(selectedOfferIndex)}
                                        disabled={!termsAccepted}
                                        text="Confirm and Accept"
                                        className="ml-auto"
                                    />
                                </div>
                            </>
                        ) : (
                            <p className="text-center">No offer selected.</p>
                        )
                        }
                    </div >
                );
            case 6:
                return (
                    <div className="">
                        {/* Vendor Payment Confirmation */}
                        <div className="mt-2 bg-green-200 text-green-700 rounded-md p-4 flex flex-row gap-x-2">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 mt-1 mb-auto">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 0 1-1.043 3.296 3.745 3.745 0 0 1-3.296 1.043A3.745 3.745 0 0 1 12 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 0 1-3.296-1.043 3.745 3.745 0 0 1-1.043-3.296A3.745 3.745 0 0 1 3 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 0 1 1.043-3.296 3.746 3.746 0 0 1 3.296-1.043A3.746 3.746 0 0 1 12 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 0 1 3.296 1.043 3.746 3.746 0 0 1 1.043 3.296A3.745 3.745 0 0 1 21 12Z" />
                            </svg>
                            <div>
                                <h3 className=" font-semibold mb-1">Offer Accepted Successfully</h3>
                                <p className="text-sm text-gray-400">
                                    Your payment plan has been set up for Bill {billData?.invoiceNumber} ({project?.name}). Initial processing fee of {offers[selectedOfferIndex]?.processingAmount} will be charged today.
                                </p>
                            </div>
                        </div>
                        <div className="mt-2 flex flex-col gap-y-6 border border-gray-200 rounded-md p-4">
                            <h3 className="text-lg font-semibold mb-2">Next Steps</h3>

                            {/* Step 1 */}
                            <div className="flex flex-row gap-x-4">
                                <p className="text-sm text-gray-700 font-medium grid place-content-center bg-gray-100 rounded-full w-8 h-8 border">1</p>
                                <div className="flex flex-col gap-y-1">
                                    <p className="text-sm font-semibold text-gray-800">Payment Confirmation</p>
                                    <p className="text-sm text-gray-600">
                                        You will receive an email confirmation of your payment plan setup within the next hour.
                                    </p>
                                </div>
                            </div>

                            {/* Step 2 */}
                            <div className="flex flex-row gap-x-4">
                                <p className="text-sm text-gray-700 font-medium grid place-content-center bg-gray-100 rounded-full w-8 h-8 border">2</p>
                                <div className="flex flex-col gap-y-1">
                                    <p className="text-sm font-semibold text-gray-800">Vendor Notification</p>
                                    <p className="text-sm text-gray-600">
                                        {billData?.vendorName} will be notified of your payment within 24 hours.
                                    </p>
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="flex flex-row gap-x-4">
                                <p className="text-sm text-gray-700 font-medium grid place-content-center bg-gray-100 rounded-full w-8 h-8 border">3</p>
                                <div className="flex flex-col gap-y-1">
                                    <p className="text-sm font-semibold text-gray-800">Initial Payment Processing</p>
                                    <p className="text-sm text-gray-600">
                                        Your initial deposit of {renderCurrency(offers[selectedOfferIndex]?.depositAmount)} and processing fee of {renderCurrency(offers[selectedOfferIndex]?.processingAmount)} will be debited within 24 hours.
                                    </p>
                                </div>
                            </div>

                            <div className="flex flex-row w-full">
                                <Button
                                    onClick={() => { document.body.classList.remove('overflow-hidden'); navigate('/financing'); }}
                                    text="View Materials Financing"
                                    className="ml-auto"
                                />

                            </div>
                        </div>

                    </div>
                );
            default:
                return null;
        }
    };

    const updateFinancingRequest = async (updatedFinancingRequest) => {
        try {
            await api_update(
                `${process.env.REACT_APP_API_URL}/financing-request/${financingRequest._id}`,
                updatedFinancingRequest
            );
        } catch (err) {
            console.error(err);
        }
    };

    const handleConfirmOffer = async (index) => {
        let newOffers = [...offers];
        newOffers[index].status = 'Accepted';
        setOffers(newOffers);

        const updatedFinancingRequest = {
            ...financingRequest,
            offers: newOffers,
            status: 'Approved',
        };
        await updateFinancingRequest(updatedFinancingRequest);

        // Create a new Loan from the accepted offer
        let newLoanId = null;
        const acceptedOffer = newOffers[index];
        try {
            const newLoanResp = await api_post(
                `${process.env.REACT_APP_API_URL}/loan`,
                {
                    data: {
                        principal: acceptedOffer.amount,
                        interestRate: acceptedOffer.interestRate,
                        termLengthWeeks: acceptedOffer.termLength,
                        financingRequest: financingRequest._id,
                        bill: billData._id,
                        project: billData?.project?._id,
                        approvedAdvanceRate: acceptedOffer.approvedAdvanceRate,
                        depositAmount: acceptedOffer.depositAmount,
                        advanceAmount: acceptedOffer.advanceAmount,
                        processingAmount: acceptedOffer.processingAmount,
                        totalPaybackAmount: acceptedOffer.totalPaybackAmount,
                        periodicPaymentAmount: acceptedOffer.periodicPaymentAmount,
                        totalInterestAmount: acceptedOffer.totalInterestAmount,
                        processingRate: acceptedOffer.processingRate,
                    }
                }
            );
            if (newLoanResp && newLoanResp.data && newLoanResp.data.loan) {
                newLoanId = newLoanResp.data.loan._id;
            }
        } catch (loanError) {
            console.error('Error creating a new loan:', loanError);
        }

        // Create a loan snapshot with the accepted offer
        // and store the financingRequest + loan references
        const snapshotData = {
            financingRequest: updatedFinancingRequest,
            acceptedOffer,
            snapshotCreatedAt: new Date().toISOString()
        };
        const loanSnapshotString = JSON.stringify(snapshotData);

        try {
            const response = await api_post(
                `${process.env.REACT_APP_API_URL}/loan-snapshot`,
                {
                    loanSnapshotString,
                    acceptedOffer,
                    financingRequest: updatedFinancingRequest._id,
                    loan: newLoanId
                }
            );
            if (response && response.data && response.data.status === 'success') {
                console.log('Loan snapshot created with accepted offer.');
            } else {
                console.error('Failed to create loan snapshot for accepted offer', response);
            }
        } catch (error) {
            console.error('Error creating loan snapshot for accepted offer', error);
        }

        // Create ACH authorization record (indicating user accepted T&C).
        try {
            const ipRes = await fetch("https://api.ipify.org?format=json");
            const ipData = await ipRes.json();
            await api_post(`${process.env.REACT_APP_API_URL}/ach-authorization`, {
                isAuthorized: true,
                ipAddress: ipData?.ip || "",
            });
        } catch (achError) {
            console.error("Error creating ACH authorization record", achError);
        }

        setCurrentStep(6);
    };

    return (
        <Modal
            onClose={onClose}
            blur={true}
            darken={true}
            containerClassName="w-full max-w-6xl mx-auto p-2 flex flex-col max-h-[92vh] overflow-y-auto"
        >
            <div className="flex items-center justify-center mb-2">
                <img src={logo} className="w-24" alt="logo" />
                <h1 className="text-xl ml-1 font-bold">Financing Request</h1>
            </div>
            {loading ? <Loading />
                :
                <div className="p-6 rounded-lg flex-grow flex flex-col">
                    {renderStepContent()}
                </div>
            }
        </Modal>
    );
}