import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getUserAsCustomer } from '../utils';
import { toast } from 'react-toastify';
import { api } from '../api.service';

export default function QuickbooksButton({
    user,
    text,
    onStatusChange,
    redirectUri,
    connectionStatus: controlledConnectionStatus, // Optional prop
    setConnectionStatus: controlledSetConnectionStatus, // Optional prop
    simple = false,
    containerClassName = '',
}) {
    const isConnectionStatusControlled =
        controlledConnectionStatus !== undefined &&
        controlledSetConnectionStatus !== undefined;

    const [localConnectionStatus, setLocalConnectionStatus] = useState('notConnected');

    const connectionStatus = isConnectionStatusControlled
        ? controlledConnectionStatus
        : localConnectionStatus;
    const setConnectionStatus = isConnectionStatusControlled
        ? controlledSetConnectionStatus
        : setLocalConnectionStatus;

    const [localUser, setLocalUser] = useState(user);

    useEffect(() => {
        const fetchUserAndStatus = async () => {
            try {
                let currentUser = localUser;

                if (!currentUser || !currentUser._id) {
                    currentUser = await getUserAsCustomer();
                    setLocalUser(currentUser);
                }

                if (!currentUser?._id) {
                    throw new Error('User ID not found');
                }

                const status = await getQuickbooksConnectionStatus();
                setConnectionStatus(status);

                if (onStatusChange) {
                    onStatusChange(status);
                }
            } catch (error) {
                console.error('Error fetching user or connection status:', error);
                setConnectionStatus("Not Connected");
            }
        };
        fetchUserAndStatus();
    }, []);

    const getQuickbooksConnectionStatus = async () => {
        try {
            const response = await api(`${process.env.REACT_APP_API_URL}/quickbooks/`);

            if (!response) {
                return 'notConnected';
            }

            const data = await response;
            const { x_refresh_token_expires_in, createdAt } = data;

            const xRefreshTokenExpiresIn = Number(x_refresh_token_expires_in);
            if (isNaN(xRefreshTokenExpiresIn)) {
                throw new Error('Invalid x_refresh_token_expires_in');
            }

            const createdAtDate = new Date(createdAt);
            const expirationDate = new Date(
                createdAtDate.getTime() + xRefreshTokenExpiresIn * 1000
            );

            const now = new Date();

            if (now >= expirationDate) {
                return 'expired';
            } else {
                return 'connected';
            }
        } catch (error) {
            console.error('Error fetching QuickBooks connection status:', error);
            return 'notConnected';
        }
    };

    const handleQuickbooksClick = async () => {
        try {
            let businessID =
                typeof localUser === 'object' ? localUser?.business?._id : localUser.business;

            if (!businessID) {
                const userResponse = await getUserAsCustomer();
                setLocalUser(userResponse);
                businessID = userResponse?.business?._id;
            }

            if (!businessID) {
                throw new Error('Business ID not found');
            }

            const clientId = process.env.REACT_APP_QUICKBOOKS_CLIENT_ID;
            const redirectUriToUse =
                redirectUri || `${process.env.REACT_APP_API_URL}/public/quickbooks/callback/`;
            const scope = encodeURIComponent('com.intuit.quickbooks.accounting');
            const originUrl = encodeURIComponent(window.location.href);

            const quickbooksAuthUrl = `https://appcenter.intuit.com/connect/oauth2?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUriToUse}&response_type=code&state=${businessID};${originUrl}`;
            window.location.href = quickbooksAuthUrl;
        } catch (error) {
            console.error('Error during QuickBooks connection:', error);
            toast.error('Failed to connect to QuickBooks. Please try again.');
        }
    };

    const getStatusIndicator = (status) => {
        switch (status) {
            case 'connected':
                return { colorClass: 'bg-green-500 border-2 border-green-600 mr-2 shadow shadow-green-600', label: 'Connected' };
            case 'expired':
                return { colorClass: 'bg-yellow-500 mr-2 border-2 border-yellow-600 shadow shadow-yellow-600', label: 'Expired' };
            default:
                return { colorClass: 'bg-red-500 border-2 border-red-600 shadow shadow-red-600', label: '' };
        }
    };

    const statusIndicator = getStatusIndicator(connectionStatus);

    return (
        <button
            className={`px-6 py-3 rounded-md bg-secondary-100 border shadow-sm border-gray-300 flex items-center justify-start text-sm w-3/4 text-center font-semibold transition-all hover:scale-105  cursor-pointer ${simple ? '' : '!text-xs !px-1'} ${containerClassName}`}
            onClick={handleQuickbooksClick}
            type="button"
        >
            <img
                src="quickbooks-coming-soon.png"
                alt="Quickbooks"
                className="w-6 h-6 mr-2 bg-primary rounded-full p-0.5"
            />
            {text || 'Quickbooks'}
            <div className="flex items-center ml-auto bg-secondary-300 rounded-full p-1 text-xs mr-1 border border-gray-300">
                <div className={`w-4 h-4 rounded-full ${statusIndicator.colorClass}`}></div>
                <span className="text-xs">{statusIndicator.label}</span>
            </div>
        </button>
    );
}

// Define prop types
QuickbooksButton.propTypes = {
    user: PropTypes.object.isRequired,
    text: PropTypes.string,
    onStatusChange: PropTypes.func,
    redirectUri: PropTypes.string,
    connectionStatus: PropTypes.string, // Optional
    setConnectionStatus: PropTypes.func, // Optional
    simple: PropTypes.bool,
};
