import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { api, api_delete, api_update } from '../api.service';
import { BillsTable } from '../bills';
import Confirm from '../components/confirm';
import { InvoicesTable } from '../invoices';
import {
  CurrencyField,
  DateField,
  FileField,
  SelectField,
  TextAreaField,
  TextField,
} from './fields/';
import FormWrapper from './form-wrapper';
import { toast } from 'react-toastify';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { LlmFileExtractor } from "../components/llm-file-extractor";
import { PlusIcon } from '@heroicons/react/24/outline';
import FilePreviewWithMagnification from '../components/file-preview-with-magnification';
import { PROJECT_LLM_CONFIG } from '../config/llmConfigs';

function AdditionalDocumentsSection({ docs, updateDocs }) {
  useEffect(() => {
    if (docs) {
      docs.forEach((doc, index) => {
        if (doc && doc.key && !doc.key.toLowerCase().includes('.pdf') && !doc.downloadUrl) {
          api(`${process.env.REACT_APP_API_URL}/document/download-url?key=${doc.key}`)
            .then((res) => {
              if (res?.url) {
                const newDocs = [...docs];
                newDocs[index] = { ...doc, url: res.url, downloadUrl: res.url };
                updateDocs(newDocs);
              }
            })
            .catch((err) => {
              console.error("Error fetching download URL for", doc.key, err);
            });
        }
      });
    }
  }, [docs, updateDocs]);

  return (
    <div className="mt-6 border border-gray-300 rounded-lg p-5 bg-secondary-100">
      <div className="text-xl font-semibold text-gray-800 mb-4">Additional Documents</div>
      <p className='mb-8 text-gray-500 text-sm'>Upload any additional documents that are relevant to your project, they will be stored here and can be shared with your team.</p>
      <div className="space-y-8">
        {docs && docs.map((doc, index) => (
          <div key={index} className="flex items-center space-x-4">
            <div className="w-32 h-32">
              <FilePreviewWithMagnification
                fileUrl={doc?.url}
                placeholderText="No file to preview"
                previewLabel="Preview Document"
                docName={doc?.key}
                className="w-32 h-32"
              />
            </div>
            <div className="flex-1">
              <FileField
                label=""
                value={doc}
                downloadUrl={doc?.url}
                onChange={(val) => {
                  const newDocs = [...docs];
                  newDocs[index] = val;
                  updateDocs(newDocs);
                }}
                source="project_additional"
                required={false}
                returnFullDoc={true}
              />
            </div>
          </div>
        ))}
        <div className="flex justify-center">
          <button
            type="button"
            onClick={() => {
              const newDocs = docs ? [...docs, {}] : [{}];
              updateDocs(newDocs);
            }}
            className="flex items-center space-x-2 p-2 px-4 border-2 border-dashed border-gray-300 rounded text-gray-500 hover:bg-gray-100 mb-8 bg-gray-100"
          >
            <PlusIcon className="w-6 h-6" />
            <span>Add Additional Document</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default function ProjectForm(props) {
  const params = useParams();
  const navigate = useNavigate();
  let id = params.id ?? props.id;
  let [searchParams] = useSearchParams();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [bills, setBills] = useState([]);
  const [userData, setUserData] = useState();
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');
 
  const [initialised, setInitialised] = useState(false);
  const [extraBodyData, setExtraBodyData] = useState({});

  useEffect(() => {
    if(!id || id === 'new' || props.simple) return;
    api(`${process.env.REACT_APP_API_URL}/app/bill`).then((x) => {
      setBills(x.bills.filter((b) => b.project && b?.project?._id === id));
    });
    api(`${process.env.REACT_APP_API_URL}/app/invoice`).then((x) => {
      setInvoices(x.filter((inv) => inv.project === id));
    });
  }, [id]);

  function validation(values) {
    if (validCompletionDate(values.startDate, values.completedDate) === false) {
      return false;
    }
    return true;
  }

  function validCompletionDate(start, completed) {
    if (start && completed) {
      return completed.valueOf() > start.valueOf();
    }
    return true;
  }

  function deleteProject() {
    api_delete(`${process.env.REACT_APP_API_URL}/app/project/${id}`).then(() =>
      navigate(-1)
    );
  }

  useEffect(() => {
    if (window.lendica && userData && !initialised && !buildrfiFinancingEnabled) {
      setInitialised(true);
      const credentials = {
        partner_name: process.env.REACT_APP_LENDICA_PARTNER_NAME,
        partner_company_uuid: userData.companyData.id,
        company_name: userData.companyData.companyName,
      };
      window.lendica.init(credentials).then(() => {
        window.lendica.ibranch.render();
      });
    }
  }, [window.lendica, userData]);

  const openFundNow = (id, total) => {
    window.lendica.ibranch.openFundNow(id, total);
  };
  const openPayLater = (id, total) => {
    window.lendica.ibranch.openPayLater(id, total);
  };

  async function handleFormSubmit(values) {
    if (values?.document) {
      await api_update(
        `${process.env.REACT_APP_API_URL}/document/${values?.document}`,
        { source: 'project', sourceId: values._id }
      );
    }
    if (searchParams.get('rd')) {
      navigate(searchParams.get('rd'));
    } else {
      if (!props.simple) {
        const path = '/project/' + values.data._id;
        navigate(path);
        toast.success('Project saved successfully');
      }
      if (props.callback) props.callback();
    }
  }

  return (
    <div className="px-8">
      <Confirm
        open={confirmDelete}
        confirm={() => deleteProject()}
        cancel={() => setConfirmDelete(false)}
      />
      <FormWrapper
        url="app/project"
        id={id}
        {...props}
        callback={(values) => handleFormSubmit(values)}
        validate={validation}
        noStyle
        extraBodyData={extraBodyData}
      >
        {(values, setValues) => (
          <>
            <div className="text-3xl font-bold tracking-tighter text-gray-800 mb-2">
              Project Details
            </div>
<LlmFileExtractor
                label="Contract"
                value={values.document}
                config={PROJECT_LLM_CONFIG.config}
                extraDocumentData={PROJECT_LLM_CONFIG.extraDocumentData}
                source="project"
                required
                onExtracted={(data) => {
                  const formatDate = (date) => {
                    if (!date || !Date.parse(date)) {
                      return null;
                    }
                    return new Date(date).toISOString().split("T")[0];
                  };
                  const tryParseNumber = (str, fallback) => {
                    const num = typeof (str) == String ? parseFloat((str || "").replace(/[^0-9.]/g, '')) : "";
                    return isNaN(num) ? fallback : num;
                  };
                  setValues((prevValues) => ({
                    ...prevValues,
                    name: data.name ?? values.name,
                    location: data.location ?? values.location,
                    description: data.description ?? values.description,
                    projectOwner: data.projectOwner ?? values.projectOwner,
                    mainContact: data.mainContact ?? values.mainContact,
                    mainContactEmail: data.mainContactEmail ?? values.mainContactEmail,
                    startDate: formatDate(data.startDate) ?? formatDate(values.startDate),
                    completedDate: formatDate(data.completedDate) ?? formatDate(values.completedDate),
                    status: data.status ?? values.status,
                    totalPrice: data.totalPrice
                      ? tryParseNumber(data.totalPrice, values.totalPrice)
                      : values.totalPrice,
                    deposit: data.deposit
                      ? tryParseNumber(data.deposit, values.deposit)
                      : values.deposit,
                    totalEstimatedCosts: data.totalEstimatedCosts
                      ? tryParseNumber(data.totalEstimatedCosts, values.totalEstimatedCosts)
                      : values.totalEstimatedCosts,
                  }));
                  setExtraBodyData({ extraDocumentData: data.extraDocumentData });
                }}
                onChange={(val) => {
                  setValues({ ...values, document: val });
                }}
              />
            <div className="grid grid-cols-1 gap-x-4 sm:grid-cols-2 gap-y-6">
              <TextField
                label="Name"
                value={values.name}
                onChange={(val) => setValues({ ...values, name: val })}
                required
              />
              <TextField
                label="Location"
                value={values.location}
                onChange={(val) => setValues({ ...values, location: val })}
              />
              <TextAreaField
                label="Description"
                value={values.description}
                onChange={(val) => setValues({ ...values, description: val })}
                inputClass="h-12 mt-1"
              />
            </div>
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 my-6">
              <TextField
                label="Project Owner"
                value={values.projectOwner}
                onChange={(val) => setValues({ ...values, projectOwner: val })}
                required
              />
              <TextField
                label="Main Contact"
                value={values.mainContact}
                onChange={(val) => setValues({ ...values, mainContact: val })}
                required
              />
              <TextField
                label="Main Contact Email"
                value={values.mainContactEmail}
                onChange={(val) => setValues({ ...values, mainContactEmail: val })}
                required
              />
              <DateField
                label="Project Start Date"
                value={values.startDate}
                onChange={(val) => setValues({ ...values, startDate: val })}
                required
              />
              <DateField
                label="Target Completion Date"
                value={values.completedDate}
                onChange={(val) => setValues({ ...values, completedDate: val })}
                isValid={validCompletionDate(values.startDate, values.completedDate)}
                required
                invalidText="Must be after Start Date"
              />
              <SelectField
                options={[
                  { text: 'In Review', value: 'In Review' },
                  { text: 'Active', value: 'Active' },
                  { text: 'Complete', value: 'Complete' },
                ]}
                label="Project Status"
                value={values.status}
                onChange={(val) => setValues({ ...values, status: val })}
              />
              <CurrencyField
                label="Project Contract Value"
                value={values.totalPrice}
                onChange={(val) => setValues({ ...values, totalPrice: val })}
                min={0}
              />
              <CurrencyField
                label="Deposit Paid"
                value={values.deposit}
                onChange={(val) => setValues({ ...values, deposit: val })}
                min={0}
              />
              <CurrencyField
                label="Total Estimated Costs"
                value={values.totalEstimatedCosts}
                onChange={(val) => setValues({ ...values, totalEstimatedCosts: val })}
                min={0}
              />
            </div>

            {!props.simple && <AdditionalDocumentsSection
              docs={values.additionalDocuments || []}
              updateDocs={(newDocs) =>
                setValues({ ...values, additionalDocuments: newDocs })
              }
            />}
            {id !== 'new' && (
              <div className="relative">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    setConfirmDelete(true);
                  }}
                  className="absolute top-0 left-0 inline-flex items-center justify-center px-4 py-2 mt-5 text-sm font-medium text-white border border-transparent rounded shadow-sm cursor-pointer hover-lift bg-rose-500 hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                >
                  Delete
                </button>
              </div>
            )}
          </>
        )}
      </FormWrapper>
      {!props.simple && (
        <div className='bg-secondary-100 rounded-lg p-5 mt-16 border border-gray-300'>
          <div className="mb-5 text-2xl font-semibold text-gray-800">Bills</div>
          <BillsTable openBill={openPayLater} data={bills} />
          <div className="mb-5 mt-8 text-2xl font-semibold text-gray-800">Invoices</div>
          <InvoicesTable open={openFundNow} data={invoices} />
        </div>
      )}
    </div>
  );
}