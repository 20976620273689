import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { api } from '../api.service';
import { toast, ToastContainer } from 'react-toastify';
import Loading from '../components/loading';
import PageHeader from '../components/page-header';
import { getStatusClass } from '../utils';
import { BanknotesIcon } from '@heroicons/react/24/outline';

/**
 * A detail page that shows a single Loan. It fetches from /loan/:id.
 * We display:
 * - Loan info: principal, interestRate, term, status, createdAt, updatedAt
 * - Payment schedule
 * - References to Bill/Project/FinancingRequest
 * - List of transactions
 * - A form to add a new transaction (payment)
 */

export default function AdminLoanDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loan, setLoan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [transactions, setTransactions] = useState([]);
  const [newTransaction, setNewTransaction] = useState({
    amount: '',
    paymentMethod: '',
    notes: '',
    type: '',
    direction: 'inbound',
    paymentRail: '',
    status: 'pending',
    externalId: '',
    dateStarted: '',
    dateCompleted: ''
  });

  useEffect(() => {
    fetchLoan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const fetchLoan = async () => {
    setLoading(true);
    try {
      const result = await api(`${process.env.REACT_APP_API_URL}/loan/${id}`);
      setLoan(result);
      const txs = await api(`${process.env.REACT_APP_API_URL}/transaction?loanId=${id}`).catch(() => []);
      setTransactions(txs);
    } catch (err) {
      console.error(err);
      setError('Error loading loan data');
      toast.error('Failed to load loan details');
    } finally {
      setLoading(false);
    }
  };

  const renderValue = (value, isCurrency = false, isDate = false) => {
    if (value === null || value === undefined) {
      return <span className="text-yellow-500">Missing</span>;
    }
    if (isCurrency) return '$' + parseFloat(value).toFixed(2);
    if (isDate) return new Date(value).toLocaleString();
    return value;
  };

  const handleCreateTransaction = async (e) => {
    e.preventDefault();
    if (!newTransaction.amount || Number(newTransaction.amount) <= 0) {
      toast.error('Please enter a valid transaction amount');
      return;
    }
    try {

      await api(`${process.env.REACT_APP_API_URL}/transaction`, {
        ...newTransaction,
        amount: parseFloat(newTransaction.amount),
        loanId: id
      });
      toast.success('Transaction created!');
      setNewTransaction({ amount: '', paymentMethod: '', notes: '' });
      fetchLoan();
    } catch (err) {
      console.error(err);
      toast.error('Failed to create transaction');
    }
  };

  if (loading) return <Loading />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!loan) return null;

  const {
    principal,
    interestRate,
    termLengthWeeks,
    status,
    paymentSchedule,
    project,
    financingRequest,
    bill,
    createdAt,
    updatedAt,
  } = loan;

  return (
    <div className="flex flex-col pb-36 px-6">
      <ToastContainer position="top-right" autoClose={2000} />
      <PageHeader headline="Loan Detail" showBackButton="true" />
      <div className="container my-10">
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
          <h2 className="text-3xl font-medium mb-4 flex items-center">
            <BanknotesIcon className="w-8 h-8 mr-2" />
            Loan Overview
          </h2>
          <div className="grid gap-y-2 border rounded-md">
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Loan ID:</span>
              <span>{renderValue(loan._id)}</span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Principal:</span>
              <span>{renderValue(principal, true)}</span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Interest Rate:</span>
              <span>
                {interestRate ? `${(interestRate * 100).toFixed(2)}%` : 'N/A'}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Term (weeks):</span>
              <span>{renderValue(termLengthWeeks)}</span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Status:</span>
              <span className={getStatusClass(status)}>
                {status || 'unknown'}
              </span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Created At:</span>
              <span>{renderValue(createdAt, false, true)}</span>
            </div>
            <div className="grid grid-cols-2 gap-x-4 py-2 odd:bg-gray-100 px-4">
              <span className="font-medium">Updated At:</span>
              <span>{renderValue(updatedAt, false, true)}</span>
            </div>
          </div>
        </div>

        {/* References: FinancingRequest, Project, Bill */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-primary shadow-sm border rounded-lg p-6">
            <h2 className="text-2xl font-medium mb-4">Financing Request</h2>
            {financingRequest ? (
              <div className="space-y-2 text-sm">
                <p>
                  ID:{' '}
                  <span
                    className="text-blue-600 underline cursor-pointer"
                    onClick={() => navigate(`/admin/financing-request/detail/${financingRequest._id}`)}
                  >
                    {financingRequest._id}
                  </span>
                </p>
                <p>Status: {financingRequest.status || 'unknown'}</p>
                <p>Amount: {renderValue(financingRequest.amount, true)}</p>
              </div>
            ) : (
              <p className="text-yellow-500">No associated financing request</p>
            )}
          </div>
          <div className="bg-primary shadow-sm border rounded-lg p-6">
            <h2 className="text-2xl font-medium mb-4">Project</h2>
            {project ? (
              <div className="space-y-2 text-sm">
                <p>
                  Project ID:{' '}
                  <span
                    className="text-blue-600 underline cursor-pointer"
                    onClick={() => navigate(`/admin/project/detail/${project._id}`)}
                  >
                    {project._id}
                  </span>
                </p>
                <p>Name: {project.name || 'N/A'}</p>
                <p>Owner: {project.owner || 'N/A'}</p>
              </div>
            ) : (
              <p className="text-yellow-500">No associated project</p>
            )}
          </div>
          <div className="bg-primary shadow-sm border rounded-lg p-6">
            <h2 className="text-2xl font-medium mb-4">Bill</h2>
            {bill ? (
              <div className="space-y-2 text-sm">
                <p>
                  Bill ID:{' '}
                  <span
                    className="text-blue-600 underline cursor-pointer"
                    onClick={() => navigate(`/admin/bill/detail/${bill._id}`)}
                  >
                    {bill._id}
                  </span>
                </p>
                <p>Vendor: {bill.vendorName || 'N/A'}</p>
                <p>Invoice#: {bill.invoiceNumber || 'N/A'}</p>
              </div>
            ) : (
              <p className="text-yellow-500">No associated bill</p>
            )}
          </div>
        </div>

        {/* Payment Schedule */}
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-medium mb-4">Payment Schedule</h2>
          {Array.isArray(paymentSchedule) && paymentSchedule.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-primary text-sm">
                <thead className="bg-gray-100 border-b">
                  <tr>
                    <th className="px-4 py-2 text-left">Due Date</th>
                    <th className="px-4 py-2 text-left">Status</th>
                    <th className="px-4 py-2 text-right">Principal Portion</th>
                    <th className="px-4 py-2 text-right">Interest Portion</th>
                    <th className="px-4 py-2 text-right">Total Due</th>
                    <th className="px-4 py-2 text-right">Amount Paid</th>
                    <th className="px-4 py-2 text-right">Balance</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentSchedule.map((ps, i) => {
                    const balanceDue = ps.totalDue - (ps.amountPaid ?? 0);
                    return (
                      <tr key={i} className="border-b">
                        <td className="px-4 py-2">
                          {renderValue(ps.dueDate, false, true)}
                        </td>
                        <td className="px-4 py-2">
                          <span className={getStatusClass(ps.status)}>
                            {ps.status}
                          </span>
                        </td>
                        <td className="px-4 py-2 text-right">
                          {renderValue(
                            ps.principalPortion != null
                              ? ps.principalPortion
                              : ps.totalDue - (ps.interestPortion != null ? ps.interestPortion : ps.totalDue * interestRate),
                            true
                          )}
                        </td>
                        <td className="px-4 py-2 text-right">
                          {renderValue(
                            ps.interestPortion != null
                              ? ps.interestPortion
                              : ps.totalDue * interestRate,
                            true
                          )}
                        </td>
                        <td className="px-4 py-2 text-right">
                          {renderValue(ps.totalDue, true)}
                        </td>
                        <td className="px-4 py-2 text-right">
                          {renderValue(ps.amountPaid, true)}
                        </td>
                        <td className="px-4 py-2 text-right">
                          {renderValue(balanceDue, true)}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-yellow-500">No payment schedule found.</p>
          )}
        </div>

        {/* Transactions */}
        <div className="bg-primary shadow-sm border rounded-lg p-6 mb-8">
          <h2 className="text-2xl font-medium mb-4">Transactions</h2>
          {Array.isArray(transactions) && transactions.length > 0 ? (
            <div className="overflow-x-auto mb-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Created At</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Type</th>
                    <th className="px-4 py-2 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Direction</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Payment Rail</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">External ID</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Started</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date Completed</th>
                    <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Notes</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {transactions.map(tx => (
                    <tr key={tx._id}>
                      <td className="px-4 py-2 whitespace-nowrap">{renderValue(tx.createdAt, false, true)}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.type}</td>
                      <td className="px-4 py-2 whitespace-nowrap text-right">{tx.amount}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.direction}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.paymentRail}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.status}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.externalId}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.dateStarted ? new Date(tx.dateStarted).toLocaleString() : ''}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.dateCompleted ? new Date(tx.dateCompleted).toLocaleString() : ''}</td>
                      <td className="px-4 py-2 whitespace-nowrap">{tx.notes}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-yellow-500">No transactions found.</p>
          )}
        </div>

        {/* Form to add new transaction */}
        <div className="border-t pt-4 mt-4">
          <h3 className="text-xl font-medium mb-2">Add a Payment</h3>
          <form onSubmit={handleCreateTransaction} className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <label className="block">
              <span className="text-gray-700">Type</span>
              <select
                value={newTransaction.type}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, type: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              >
                <option value="">Select type</option>
                <option value="vendorPayment">Vendor Payment</option>
                <option value="collection">Collection</option>
                <option value="fee">Fee</option>
                <option value="reversal">Reversal</option>
                <option value="manualAdjustment">Manual Adjustment</option>
              </select>
            </label>
            <label className="block">
              <span className="text-gray-700">Direction</span>
              <select
                value={newTransaction.direction}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, direction: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              >
                <option value="inbound">Inbound</option>
                <option value="outbound">Outbound</option>
              </select>
            </label>
            <label className="block">
              <span className="text-gray-700">Payment Rail</span>
              <select
                value={newTransaction.paymentRail}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, paymentRail: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              >
                <option value="">Select Payment Rail</option>
                <option value="FedNow">FedNow</option>
                <option value="RTP">RTP</option>
                <option value="Same Day ACH">Same Day ACH</option>
                <option value="ACH">ACH</option>
                <option value="Wires">Wires</option>
              </select>
            </label>
            <label className="block">
              <span className="text-gray-700">External ID</span>
              <input
                type="text"
                value={newTransaction.externalId}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, externalId: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
                placeholder="External ID"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Date Started</span>
              <input
                type="datetime-local"
                value={newTransaction.dateStarted}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, dateStarted: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Date Completed</span>
              <input
                type="datetime-local"
                value={newTransaction.dateCompleted}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, dateCompleted: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
              />
            </label>
           
            <label className="block">
              <span className="text-gray-700">Amount</span>
              <input
                type="number"
                step="0.01"
                value={newTransaction.amount}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, amount: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2"
                required
              />
            </label>
            <label className="block">
              <span className="text-gray-700">Notes</span>
              <textarea
                value={newTransaction.notes}
                onChange={(e) => setNewTransaction(prev => ({ ...prev, notes: e.target.value }))}
                className="mt-1 block w-full border border-gray-300 rounded p-2 h-24"
                placeholder="Optional notes"
              />
            </label>
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 col-span-full"
            >
              Submit Transaction
            </button>
          </form>
        </div>
      </div >
    </div >
  );
}