import { useEffect, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { useRole } from './hooks/useRole';
import { MANAGER_ROLE_NAME } from './utils';
import logo from './logo.png';
import logoWhite from './logo-white.png';
import { api } from './api.service';
import Loading from './components/loading';
import smallLogo from './smallLogo.png';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import useDarkMode from './hooks/useDarkMode';

import {
  IconLayoutDashboard,
  IconLayoutSidebar,
  IconLogout2,
  IconMailDollar,
  IconMoneybag,
  IconMoonStars,
  IconReceiptDollar,
  IconSettings,
  IconUserDollar,
  IconHammer,
} from '@tabler/icons-react';

import {
  UserGroupIcon,
  BuildingStorefrontIcon,
} from '@heroicons/react/24/outline';

import posthog from 'posthog-js';
import { Bars3Icon } from '@heroicons/react/20/solid';

let BOTTOM_NAVIGATION = [
  { name: 'Settings', href: '/settings', icon: IconSettings },
  { name: 'Team', href: '/user', icon: UserGroupIcon },
  { name: 'Toggle Dark Mode', href: '#', icon: IconMoonStars },
  { name: 'Logout', href: '/logout', icon: IconLogout2 },
];

const classNames = (...classes) => classes.filter(Boolean).join(' ');

export default function Layout() {
  const [sidebarOpen, setSidebarOpen] = useState(() => {
    const stored = localStorage.getItem('sidebarOpen');
    return stored !== null ? JSON.parse(stored) : true;
  });
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [expandedItems, setExpandedItems] = useState([]);
  const buildrfiFinancingEnabled = useFeatureFlagEnabled('buildrfi_financing');
  const { darkMode, setDarkMode } = useDarkMode();

  const TOP_NAVIGATION = [
    { name: 'Dashboard', href: '/', icon: IconLayoutDashboard, border: true },
    { name: 'Projects', href: '/projects', icon: IconHammer },
    {
      name: 'Bills',
      href: '/bills',
      icon: IconReceiptDollar,
      subItems: [{ name: 'Vendors', href: '/vendors', icon: BuildingStorefrontIcon }],
    },
    {
      name: 'Invoices',
      href: '/invoices',
      icon: IconMailDollar,
      border: buildrfiFinancingEnabled,
      subItems: [{ name: 'Customers', href: '/customers', icon: IconUserDollar }],
    },
  ];

  if (buildrfiFinancingEnabled) {
    TOP_NAVIGATION.push({ name: 'Financing', href: '/financing', icon: IconMoneybag });
  }

  const { pathname } = useLocation();
  const { isAllowed: isManager } = useRole(MANAGER_ROLE_NAME);

  useEffect(() => {
    if (user?.email) {
      posthog.identify(user.email);
    }
  }, [user]);

  useEffect(() => {
    localStorage.setItem('sidebarOpen', JSON.stringify(sidebarOpen));
  }, [sidebarOpen]);

  useEffect(() => {
    const checkEndingStatus = () => {
      const status = user.stripeSubscriptionStatus;
      if (status?.startsWith('Ending')) {
        const dateString = status.replace('Ending on ', '');
        const endDate = new Date(dateString);
        if (endDate < new Date()) {
          window.location.assign('/pricing');
        } else {
          setLoading(false);
        }
      }
    };

    if (window.location.href.includes('pricing')) {
      setLoading(false);
      return;
    }

    if (user) {
      if (user.roles.name === 'Employee') {
        BOTTOM_NAVIGATION = [
          { name: 'Settings', href: '/settings', icon: IconSettings },
          { name: 'Toggle Dark Mode', href: '#', icon: IconMoonStars },
          { name: 'Logout', href: '/logout', icon: IconLogout2 },
        ];
      }
      if (
        user.hasFreeAccess ||
        user.stripeSubscriptionStatus === 'active' ||
        user.stripeSubscriptionStatus === 'trialing'
      ) {
        setLoading(false);
        return;
      } else if (user.stripeSubscriptionStatus?.startsWith('Ending')) {
        checkEndingStatus();
        return;
      } else {
        window.location.assign('/pricing');
      }
    } else {
      api(`${process.env.REACT_APP_API_URL}/app/settings`).then((_user) => {
        setUser(_user ?? null);
      });
    }
  }, [user, pathname]);

  const handleToggle = (name) => {
    setExpandedItems((prev) =>
      prev.includes(name) ? prev.filter((n) => n !== name) : [...prev, name],
    );
  };

  const renderNavItems = (items, onItemClick, alwaysShowText = false) =>
    items.map((item) => (
      <div key={item.name}>
        <div
          className={classNames(
            pathname.endsWith(item.href)
              ? 'bg-yellow-300 hover:bg-yellow-500'
              : 'hover:bg-gray-200',
            'group flex items-center text-xl md:text-sm rounded px-3 py-1 transition-all relative'
          )}
          aria-current={item.current ? 'page' : undefined}
        >
          <Link
            to={item.href}
            onClick={() => {
              if (onItemClick) onItemClick();
              if (item.subItems) handleToggle(item.name);
            }}
            className={classNames(
              'flex items-center w-full',
              sidebarOpen ? '' : 'justify-center'
            )}
          >
            <item.icon
              className={classNames(
                'flex-shrink-0',
                alwaysShowText || sidebarOpen ? 'w-7 h-7 mr-4' : 'w-7 h-7'
              )}
              aria-hidden="true"
              strokeWidth={1.5}
            />
            {(alwaysShowText || sidebarOpen) && item.name}
            {!alwaysShowText && !sidebarOpen && (
              <span className="absolute left-full top-1/2 transform -translate-y-1/2 ml-6 whitespace-nowrap px-2 py-1 text-sm bg-gray-900 text-primary rounded opacity-0 group-hover:opacity-100 transition-all duration-200 pointer-events-none z-50">
                {item.name}
              </span>
            )}
          </Link>
        </div>
        {item.subItems && (alwaysShowText || sidebarOpen) && (
          <div className="rounded w-full flex flex-col items-center md:items-start">
            {item.subItems.map((subItem) => (
              <Link
                key={subItem.name}
                to={subItem.href}
                onClick={() => {
                  if (onItemClick) onItemClick();
                }}
                className={classNames(
                  pathname.endsWith(subItem.href)
                    ? 'bg-yellow-300 hover:bg-yellow-500'
                    : 'text-gray-900 hover:bg-gray-200 transition-all',
                  'group flex items-center px-3 mt-8 md:mt-0 md:py-1 text-xl md:text-sm cursor-pointer md:ml-9 rounded text-center'
                )}
                aria-current={subItem.current ? 'page' : undefined}
              >
                {subItem.icon && (
                  <subItem.icon className="w-6 h-6 mr-2" strokeWidth={1.5} />
                )}
                {subItem.name}
              </Link>
            ))}
          </div>
        )}
        {item.border && <div className="border-gray-300 border-b w-full my-4" />}
      </div>
    ));

  if (loading) return <Loading />;

  return (
    <>
      <div
        className={classNames(
          'min-h-full h-max relative flex-grow flex flex-col md:flex-row',
          sidebarOpen ? 'md:pl-52' : 'md:pl-16'
        )}
      >
        {/* ============ Desktop Sidebar ============ */}
        <div className="hidden md:flex">
          <div
            className={classNames(
              sidebarOpen ? 'w-52' : 'w-16',
              'bg-primary h-screen transition-all duration-100 flex flex-col border-r-2 border-gray-100 fixed top-0 left-0 z-10 px-2'
            )}
          >
            <div className="flex items-center justify-between p-2 mt-4 relative">
              <Link to="/" className="mx-auto relative">
                {sidebarOpen ? (
                  <img className={`object-cover ${sidebarOpen ? 'w-28' : 'w-8'}`} src={darkMode ? logoWhite : logo} alt="Logo" />
                ) : (
                  <img
                    className={`object-cover ${sidebarOpen ? 'w-28' : 'w-8'
                      } bg-gray-800 rounded-lg shadow-md box-content p-2 -top-1 relative -left-2`}
                    src={smallLogo}
                    alt="Logo"
                  />
                )}
              </Link>
              {sidebarOpen && (
                <button onClick={() => setSidebarOpen(false)}>
                  <IconLayoutSidebar strokeWidth={1.5} className="w-6 h-6 absolute top-4 right-1" />
                </button>
              )}
            </div>
            <nav className="flex-1 mt-2">
              <div className="flex flex-col gap-y-1">
                {renderNavItems(TOP_NAVIGATION)}
              </div>
            </nav>
            <div
              className={classNames(
                'shadow-inner bg-gray-100',
                sidebarOpen
                  ? 'flex flex-row items-center space-x-4 justify-center mx-auto mb-4 px-6 py-2 rounded-full'
                  : 'flex flex-col items-center space-y-4 py-4 mb-4 rounded-full',
              )}
            >
              {BOTTOM_NAVIGATION.map((item) => (
                <Link key={item.name} to={item.href}>
                  <item.icon
                    strokeWidth={2}
                    className={classNames(
                      'hover:opacity-70 transition-all hover:scale-110',
                      sidebarOpen ? 'w-5 h-5' : 'w-5 h-5',
                      item.name === 'Logout' && 'text-red-500 hover:text-red-700',
                    )}
                    onClick={
                      item.name === 'Toggle Dark Mode'
                        ? () => setDarkMode(!darkMode)
                        : undefined
                    }
                  />
                </Link>
              ))}
            </div>
          </div>
        </div>

        {/* ============ Mobile Header & Hamburger Button ============ */}
        <div className="md:hidden flex flex-row items-center p-4">
          <button
            onClick={() => setMobileMenuOpen(true)}
            className="p-4 focus:outline-none focus:ring focus:ring-gray-300 bg-primary rounded-full border-gray-300 border-4"
          >
            <Bars3Icon className="w-10 h-10 text-gray-900" />
          </button>
          <img className="object-cover w-36 ml-4" src={darkMode ? logoWhite : logo} alt="Logo" />
        </div>

        {/* ============ Mobile Drawer Menu ============ */}
        {mobileMenuOpen && (
          <div className="fixed inset-0 z-50 flex md:hidden">
            {/* Backdrop */}
            <div
              className="fixed inset-0 bg-black opacity-50"
              onClick={() => setMobileMenuOpen(false)}
            ></div>
            <div className="relative bg-primary w-[80vw] h-full p-4 overflow-y-auto">
              <div className="flex items-center justify-between mb-4">
                <Link to="/" className="mx-auto relative" onClick={() => setMobileMenuOpen(false)}>
                  <img className="object-cover w-36" src={darkMode ? logoWhite : logo} alt="Logo" />
                </Link>
                <button onClick={() => setMobileMenuOpen(false)} className="p-2 rounded-md focus:outline-none">
                  {/* Simple “X” icon */}
                  <svg className="w-6 h-6 text-gray-800" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div className="flex flex-col gap-y-8 relative bottom-0">
                {renderNavItems(TOP_NAVIGATION, () => setMobileMenuOpen(false), true)}
              </div>
              <div
                className={classNames(
                  'flex flex-col items-center space-y-10 text-xl justify-center bg-secondary-100 mx-auto mt-64 px-6 py-2 rounded-md'
                )}
              >
                {BOTTOM_NAVIGATION.map((item) => (
                  <Link key={item.name} className='flex flex-row items-center justify-center w-full' to={item.href} onClick={() => setMobileMenuOpen(false)}>
                    <item.icon
                      strokeWidth={2}
                      className={classNames(
                        'hover:opacity-70 transition-all hover:scale-110 mr-2',
                        'w-5 h-5',
                        item.name === 'Logout' && 'text-red-500 hover:text-red-700'
                      )}
                      onClick={
                        item.name === 'Toggle Dark Mode'
                          ? () => setDarkMode(!darkMode)
                          : undefined
                      }
                    />
                    <p className="text-xl ">{item.name}</p>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* ============ Main Content ============ */}
        <div className="flex-1 min-h-screen bg-secondary">
          {/* Top menu button when sidebar is collapsed */}
          {!sidebarOpen && (
            <div className="hidden md:flex">
              <button onClick={() => setSidebarOpen(true)}>
                <IconLayoutSidebar
                  strokeWidth={1.5}
                  className="w-7 h-7 text-gray-600 absolute left-18 top-2" // CHANGED
                />
              </button>
            </div>
          )}
          <main className="flex-1 xl:p-10">
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
}